import './style.css'

export const Spinner = () => {
  return (
    <div className="loading">
      <div className="arc"></div>
      <div className="arc"></div>
      <div className="arc"></div>
    </div>
  );
};
