import { useEffect } from 'react';
import { MainHeader } from '../MainHeader/MainHeader';
import './style.css';

export const AuthLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainHeader />
      <main className='container layout-main'>{children}</main>
    </>
  );
};
