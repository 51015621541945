import {IntroHelmet} from './IntroHelmet';
import {MainLayout} from '../../components/Layouts/MainLayout';
import ImagineBlock from './components/ImagineBlock';
import HowItWorksBlock from './components/HowItWorksBlock';
import FirstAvatarBlock from './components/FirstAvatarBlock';
import './style.css';
import VideoBlock from '../../components/VideoBlock/VideoBlock';

const Intro = () => {
  return (
    <>
      <IntroHelmet/>
      <MainLayout>
        <div className="container intro-container">
          <VideoBlock/>
          <ImagineBlock/>
          <HowItWorksBlock/>
          <FirstAvatarBlock/>
        </div>
      </MainLayout>
    </>
  );
};

export default Intro;
