import { useContext } from 'react';
import { MainLayout } from '../../components/Layouts/MainLayout';
import { ServicesNav } from './components/ServicesNav';
import './style.css';
import { TranslationsContext } from '../../context/TranslationsContext';
import avatars from '../../assets/images/services/time-capsule-avatars.webp';
import { PhoneIcon } from '../../assets/images/services/PhoneIcon.jsx';
import { HeadingServices } from '../../components/Heading/HeadingServices';
import { OrderForm } from './components/OrderForm';
import { TimeCapsuleHelmet } from './components/TimeCapsuleHelmet';

export const ServicesTimeCapsule = () => {
  const { fullTranslations, mainLang } = useContext(TranslationsContext);

  return (
    <>
      <TimeCapsuleHelmet />
      <MainLayout>
        <main className="services-container">
          <div className="container">
            <ServicesNav />
          </div>

          <div className="container">
            <section className="services-title-block services-title-block-capsule">
              <img src={avatars} alt={'portraits'}/>
              <div className="services-title-block-content">
                <h1>{fullTranslations?.timeCapsule?.title}</h1>
                <div>{fullTranslations?.timeCapsule?.description}</div>
              </div>
              <ul className="services-title-block-steps">
                {fullTranslations?.timeCapsule?.steps?.map((step) => (
                    <li key={step}>{step}</li>
                ))}
              </ul>
              <div>
                {fullTranslations?.timeCapsule?.moreInfo}{' '}
                <a
                    href={
                      mainLang === 'ru'
                          ? 'https://youtu.be/QmIn-OjQUug'
                          : 'https://youtu.be/l1uPf2pmKNQ'
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="services-title-block-link"
                >
                  YOUTUBE
                </a>
              </div>
            </section>
            <section className="services-order">
              <div className="services-order-white">
                <div className="services-order-white-top">
                  <HeadingServices>
                    {fullTranslations?.timeCapsule?.subTitle}
                  </HeadingServices>
                  <div>{fullTranslations?.timeCapsule?.subDescription}</div>
                </div>
                <div className="services-order-black">
                  <HeadingServices>
                    {fullTranslations?.timeCapsule?.priceTitle}
                  </HeadingServices>
                  <div className="services-order-black-price">
                    {mainLang === 'ru'
                        ? `100000 ${fullTranslations?.timeCapsule?.rub}`
                        : '1000 €'}
                  </div>
                  <div className="">
                    {fullTranslations?.timeCapsule?.priceDescription}
                  </div>
                </div>
              </div>
              <div className="services-order-blue">
                <HeadingServices>
                  {fullTranslations?.timeCapsule?.orderTitle}
                </HeadingServices>

                <OrderForm/>

                <div className="services-order-phones">
                  <PhoneIcon/>
                  <a href="tel:+79055948311">+7 905 594-83-11</a> <span>|</span>
                  <a href="tel:+79251292955">+7 925 129-29-55</a>
                </div>
              </div>
            </section>
          </div>
        </main>
      </MainLayout>
    </>
  );
};
