import Img1 from '../../../assets/images/intro/intro-1.png'
import Img2 from '../../../assets/images/intro/intro-2.png'
import Img1Small from '../../../assets/images/intro/intro-1-small.png'
import Img2Small from '../../../assets/images/intro/intro-2-small.png'
import GrandfatherImgSmall from '../../../assets/images/services/connection/grandfather-small.png';
import GrandfatherImg from '../../../assets/images/services/connection/grandfather-old.png';


const ImagineBlock = () => {
    return (
      <div className={'intro-imagine'}>
          <p className={'intro-imagine-title'}>Представьте</p>
          <div className={'intro-imagine-block'}>
              <div className={'intro-imagine-block-text'}>
                  <p className={'intro-imagine-block-title'}>
                      Чего нам больше всего хочется, когда мы вспоминаем о душевных посиделках с близким, который ушел
                      из жизни?
                  </p>
                  <p className={'intro-imagine-block-subtitle'}>
                      Вернуть эти бесценные эмоции.
                  </p>
              </div>
              <img className={'intro-imagine-block-img'} src={Img1}
                   srcSet={`${Img1Small} 1700w, ${Img1} 2000w`}
              />
          </div>
          <div className={'intro-imagine-block'}>
              <img  className={'intro-imagine-block-img'} src={Img2}
                    srcSet={`${Img2Small} 1700w, ${Img2} 2000w`}
                    sizes={'(max-width: 450px)'}
              />
              <div className={'intro-imagine-block-text'}>
                  <p className={'intro-imagine-block-title'}>
                      Какова главная причина нашего отчаяния после утраты связи с близким?
                  </p>
                  <p className={'intro-imagine-block-subtitle'}>
                      Непонимание как без этой связи жить дальше.
                  </p>
              </div>
          </div>
          <p className={'intro-imagine-footer-text'}>
              Сегодня появилась возможность восстановить эмоциональную связь с близким, которого уже не существует,&nbsp;и вновь пережить те эмоции, которых так недостает,&nbsp;чтобы быть счастливым.
          </p>
      </div>
    )
}

export default ImagineBlock
