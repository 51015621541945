import './style.css';
import {
  // getRedirectResult,
  // signInWithRedirect,
  signOut,
  signInWithPopup,
  signInWithEmailAndPassword,
  // GoogleAuthProvider,
} from 'firebase/auth';
import { appleProvider, auth, googleProvider } from '../../configs/firebase';
import { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import googleIcon from '../../assets/images/google-auth-icon.svg';
import appleIcon from '../../assets/images/apple-auth-icon.svg';
import { getUserInfo } from '../../api/api';
import { toast } from 'react-toastify';
import { TranslationsContext } from '../../context/TranslationsContext';
import { Loader } from '../../components/Loaders/Loader';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo_image.svg';
import { LoginHelmet } from './components/LoginHelmet';
import { useForm } from 'react-hook-form';

export const noNetConnection = {
  ru: 'Пожалуйста, проверьте соединение с интернетом',
  en: 'Please check your internet connection',
  es: 'Por favor revise su conexion a internet',
  pt: 'Por favor, verifique sua conexão à internet',
};

export const checkNetConnection = (res, lang) => {
  if (res?.metadata.fromCache) throw new Error(noNetConnection?.[lang]);
};
export const withCheckNet = (promise, lang) =>
  promise.then((res) => {
    checkNetConnection(res, lang);
    return res;
  });

const Login = () => {
  const { logInWtoken } = useContext(AuthContext);
  const { mainLang, fullTranslations } = useContext(TranslationsContext);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  const { register, setError, handleSubmit } = useForm({ mode: 'onChange ' });

  auth.languageCode = mainLang;
  appleProvider.setCustomParameters({
    locale: mainLang,
  });

  const checkIsUserExist = async (uid) => {
    if (uid) {
      const userInfoPromise = new Promise(function (resolve) {
        const result = getUserInfo(uid);
        resolve(result);
      });
      userInfoPromise.then((value) => {
        if (!value) {
          setShowError(true);
          toast.error(fullTranslations?.login?.error);
          signOut(auth);
        } else {
          console.log(value);
          setShowError(false);
          logInWtoken(uid, value.photoPath);
        }
      });
    } else {
      signOut(auth);
      toast.error(fullTranslations?.login?.error);
    }
    setIsLoading(false);
  };

  const loginWithLoginAndPassword = ({ username, password }) => {
    withCheckNet(
      signInWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
          const user = userCredential.user;
          // console.log(user)
          const userInfoPromise = new Promise(function (resolve) {
            const result = getUserInfo(user.uid);
            resolve(result);
          });
          userInfoPromise.then((value) => {
            if (value) {
              logInWtoken(user.uid, value.photoPath);
            }
          });
        })
        .catch((error) => {
          setShowUsernameError(true);
          setShowPasswordError(true);
        }),
      mainLang
    );
  };

  const authGoogle = async () => {
    try {
      signInWithPopup(auth, googleProvider)
        .then((result) => {
          const user = result.user;
          return user;
        })
        .then((user) => {
          checkIsUserExist(user?.uid);
        });
    } catch (e) {
      toast.error(e);
    }
  };

  const authApple = async () => {
    try {
      signInWithPopup(auth, appleProvider)
        .then((result) => {
          const user = result.user;
          return user;
        })
        .then((user) => {
          checkIsUserExist(user?.uid);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <LoginHelmet />
      <main className={'auth'}>
        <Link to={`/${mainLang}`} className="auth-to-main">
          <ArrowBack />
          {fullTranslations?.login?.goBack}
        </Link>
        <div className={'auth-form'}>
          <div className={'auth-form-container'}>
            <img
              src={logo}
              alt="logo"
              width="105"
              height="102"
              className="auth-logo"
            />
            <h1>{fullTranslations?.login?.welcome} 👋🏻</h1>

            {/* {isLoading ? (
              <div style={{ height: '20px', marginBottom: '10px' }}>
                <Loader />
              </div>
            ) : (
              <div style={{ height: '20px', marginBottom: '10px' }}></div>
            )} */}
            {/* <h2>{fullTranslations?.login?.signFrom}</h2> */}
            <form
              onSubmit={handleSubmit(loginWithLoginAndPassword)}
              className={'auth-login-form'}
            >
              <label>
                {fullTranslations?.login?.email}
                <input
                  type="text"
                  placeholder={fullTranslations?.login?.enterEmail}
                  {...register('username', {
                    required: true,
                  })}
                  className={`${
                    showUsernameError ? 'auth-input_error' : ''
                  } auth-input`}
                  onChange={() => {
                    setShowUsernameError(false);
                  }}
                />
              </label>
              <label className="password">
                {fullTranslations?.login?.password}
                <input
                  type="password"
                  placeholder={fullTranslations?.login?.enterPassword}
                  {...register('password', {
                    required: true,
                  })}
                  className={`${
                    showPasswordError ? 'auth-input_error' : ''
                  } auth-input`}
                  onChange={() => {
                    setShowPasswordError(false);
                  }}
                />
              </label>
              <Link
                to={`/${mainLang}/restore-password`}
                className={'auth-link-forgot'}
              >
                {fullTranslations?.login?.forgotPassword}
              </Link>
              <button className={'login-button-password'}>
                {fullTranslations?.login?.loginButton}
              </button>

              {(showUsernameError || showPasswordError) && (
                <p className={'auth-input_error-message'}>
                  {fullTranslations?.login?.errorEmailPassword}
                  <br />
                  {fullTranslations?.login?.errorTryAgain}
                </p>
              )}
            </form>

            <h2>{fullTranslations?.login?.signFrom}</h2>
            <div className={'login-google-apple-block'}>
              <button
                type="button"
                onClick={authGoogle}
                className="login-button"
              >
                <img alt="google icon" src={googleIcon} />
                {/* {fullTranslations?.login?.continueWith} Google */}
              </button>
              <button
                type="button"
                onClick={authApple}
                className="login-button"
              >
                <img alt="google icon" src={appleIcon} />
                {/* {fullTranslations?.login?.continueWith} Apple */}
              </button>
            </div>

            {showError && (
              <div style={{ marginBottom: '30px' }}>
                {fullTranslations?.login?.error}
              </div>
            )}
          </div>
        </div>
        <div className={'auth-img'}></div>
      </main>
    </>
  );
};

export const ArrowBack = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.233 4.4545C12.6723 4.89384 12.6723 5.60616 12.233 6.0455L6.27849 12L12.233 17.9545C12.6723 18.3938 12.6723 19.1062 12.233 19.5455C11.7937 19.9848 11.0813 19.9848 10.642 19.5455L3.892 12.7955C3.45267 12.3562 3.45267 11.6438 3.892 11.2045L10.642 4.4545C11.0813 4.01517 11.7937 4.01517 12.233 4.4545Z"
      fill="#799BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12C4.5 11.3787 5.00368 10.875 5.625 10.875H19.3125C19.9338 10.875 20.4375 11.3787 20.4375 12C20.4375 12.6213 19.9338 13.125 19.3125 13.125H5.625C5.00368 13.125 4.5 12.6213 4.5 12Z"
      fill="#799BFF"
    />
  </svg>
);

export default Login;
