import { HeadingProfile } from '../../../../components/Heading/HeadingProfile';
import smile from '../../../../assets/images/smile.svg';
import noPhoto from '../../../../assets/images/no-photo-user.png';
import '../style.css';
import { useContext } from 'react';
import { TranslationsContext } from '../../../../context/TranslationsContext';

export const PersonalData = ({ userData }) => {
  const { profileTranslations } = useContext(TranslationsContext);
  
  const dataConfig = [
    { id: 1, label: profileTranslations?.profile?.name, data: userData?.name || '-' },
    {
      id: 2,
      label: profileTranslations?.profile?.dateOfBirth,
      data: userData?.dateOfBirth
        ? new Date(userData.dateOfBirth * 1000).toLocaleDateString()
        : '-',
    },
    {
      id: 3,
      label: profileTranslations?.profile?.email,
      data: userData?.email || '-',
    },
    {
      id: 4,
      label: profileTranslations?.profile?.placeOfBirth,
      data: userData?.placeOfBirth || '-',
    },
  ];

  return (
    <section className="mb-25">
      <HeadingProfile svg={smile} className="mb-15">
        {profileTranslations?.profile?.personalData}
      </HeadingProfile>
      <div className="block_container user-data">
        <img
          src={userData?.photoPath || noPhoto}
          alt="user avatar"
          width="151"
          height="151"
        />
        <div className="columns">
          {dataConfig.map((item) => (
            <div key={item.id}>
              <div className="subtitle">{item.label}</div>
              <div>{item.data}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
