import { createRef, useContext, useState } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { Controller, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { Loader } from '../../../components/Loaders/Loader';
import { sendOrderToTelegram} from '../../../api/api';

const RECAPTCHA_SITEKEY = `${process.env.REACT_APP_RECAPTCHA_SITEKEY}`;

export const OrderForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { fullTranslations, mainLang } = useContext(TranslationsContext);

  const recaptchaRef = createRef();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ shouldFocusError: true });

  const sendOrder = async (data) => {
    await recaptchaRef.current.executeAsync();

    setIsLoading(true);
    try {
      await sendOrderToTelegram(data, mainLang);
      reset({ name: '', phone: '' });
      toast.success(fullTranslations?.timeCapsule?.orderSend);
    } catch (e) {
      toast.error(e?.response?.data?.errors?.[0]?.detail);
    } finally {
      setIsLoading(false);
    }

    // axios({
    //   method: 'post',
    //   url: `${API_PATH}`,
    //   headers: { 'content-type': 'application/json' },
    //   data: data,
    // })
    //   .then((result) => {
    //     console.log(result);

    //     data.mailSent = result.data.sent;
    //   })
    //   .catch((error) => console.log(error)); //this.setState({ error: error.message }));
  };

  return (
    <form className="services-order-form" onSubmit={handleSubmit(sendOrder)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={RECAPTCHA_SITEKEY}
      />
      <div className="form-floating">
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
            validate: {
              notEmpty: (v) => String(v).trim().length > 0,
            },
          }}
          defaultValue={''}
          render={({ field }) => (
            <input
              {...field}
              className={`input form_floating_input ${
                Boolean(errors?.name) ? 'input_error' : ''
              }`}
              id="name"
              autoComplete="off"
              placeholder={fullTranslations?.timeCapsule?.name}
            />
          )}
        />

        <label htmlFor="phone" className="form-floating_label">
          {fullTranslations?.timeCapsule?.name}
        </label>
      </div>

      <div className="form-floating">
          <Controller
              name="phone"
              control={control}
              rules={{
                  required: true,
                  validate: {
                      notEmpty: (v) => String(v).trim().length > 0,
                  },
                  minLength: 5,
                  maxLength: 20,
              }}
              defaultValue={''}
              render={({ field }) => (
                  <input
                      {...field}
                      className={`input form_floating_input ${
                          Boolean(errors?.phone) ? 'input_error' : ''
                      }`}
                      type="tel"
                      id="phone"
                      autoComplete="off"
                      placeholder={fullTranslations?.timeCapsule?.phone}
                  />
              )}
          />

        <label htmlFor="phone" className="form-floating_label">
          {fullTranslations?.timeCapsule?.phone}
        </label>
      </div>

      <button className="services-order-form-button" disabled={isLoading}>
        {isLoading ? (
          <Loader isBlue={true} />
        ) : (
          fullTranslations?.timeCapsule?.send
        )}
      </button>
    </form>
  );
};
