import './style.css';
import { ErrorImage } from './components/ErrorImage';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { TranslationsContext } from '../../context/TranslationsContext';
import { NoMatchHelmet } from './components/NoMatchHelmet';

export const NoMatch = () => {
  const { fullTranslations, mainLang } = useContext(TranslationsContext);

  return (
    <>
      <NoMatchHelmet />
      <main className={'main-error'}>
        <ErrorImage />
        <h1>{fullTranslations?.noMatch?.title}</h1>
        <p className="mb-25">{fullTranslations?.noMatch?.description}</p>
        <Link to={`/${mainLang}`} className="button-transparent">
          {fullTranslations?.noMatch?.goMain}
        </Link>
      </main>
    </>
  );
};
