export const AppStoreIcon = ({ isBlack }) => (
  <svg
    width="204"
    height="67"
    viewBox="0 0 204 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7655_29320)">
      <rect
        width="204"
        height="67"
        rx="33.5"
        fill={isBlack ? '#1A1B1D' : '#4C4F53'}
        fillOpacity={isBlack ? '1' : '0.4'}
      />
      <path
        d="M41.8155 16.1723C43.3008 14.5364 44.1319 12.2905 44.0005 10C41.946 10 39.5639 11.199 38.0558 12.966C36.8753 14.4271 35.6081 16.7176 36.067 19.0072C38.1872 19.2694 40.4826 17.9393 41.8155 16.1723Z"
        fill="white"
      />
      <path
        d="M51.0978 23.4313C50.684 23.6776 46.7784 26.0025 46.7784 30.9604C46.7881 31.268 46.8196 31.5598 46.8555 31.8474H46.8537C47.5009 37.0094 52.0488 38.8551 52.1311 38.8551C52.1157 38.8746 52.0816 38.9755 52.0246 39.1439C51.7611 39.9229 51.0085 42.1478 49.3558 44.4602C47.761 46.8827 45.9902 49.3252 43.3016 49.3252C42.0688 49.3252 41.217 48.9499 40.3347 48.5611C39.3869 48.1434 38.4038 47.7102 36.8753 47.7102C35.185 47.7102 34.1413 48.1696 33.1672 48.5983C32.3149 48.9735 31.5158 49.3252 30.3833 49.3252C27.6947 49.3252 25.7934 46.7516 24.111 44.3519C21.9715 41.2766 20.1578 36.4886 20.0203 31.8474C20.0191 31.8 20.0154 31.7522 20.0117 31.7044C20.0077 31.6526 20.0037 31.6007 20.0028 31.5494C19.9582 29.0413 20.4398 26.576 21.6633 24.4822C23.3894 21.559 26.4721 19.5744 29.8377 19.5089C31.3099 19.4595 32.6896 20.0422 33.8955 20.5515C34.8021 20.9345 35.6105 21.2759 36.2859 21.2759C36.9003 21.2759 37.7325 20.9826 38.7187 20.635C40.1532 20.1294 41.9137 19.5089 43.8043 19.5089C45.181 19.5097 48.854 19.924 51.1275 23.4134C51.1217 23.417 51.1117 23.423 51.0978 23.4313Z"
        fill="white"
      />
      <path
        d="M66.1269 21.4941V11.3281H68.9402C70.7069 11.3281 72.0449 11.7345 72.9543 12.5474C73.8684 13.3603 74.3254 14.6057 74.3254 16.2835C74.3254 17.9187 73.8305 19.1972 72.8406 20.1188C71.8507 21.0357 70.5127 21.4941 68.8266 21.4941H66.1269ZM67.3204 12.4056V20.4165H68.8408C70.1764 20.4165 71.216 20.0762 71.9596 19.3957C72.7032 18.7151 73.075 17.6871 73.075 16.3118C73.075 14.946 72.7316 13.9535 72.0449 13.3343C71.3581 12.7152 70.3138 12.4056 68.9118 12.4056H67.3204Z"
        fill="white"
      />
      <path
        d="M79.1493 21.6642C78.0174 21.6642 77.1317 21.3287 76.4923 20.6576C75.8529 19.9817 75.5332 19.079 75.5332 17.9495C75.5332 16.7821 75.8884 15.8439 76.5988 15.135C77.3093 14.4214 78.1973 14.0645 79.263 14.0645C80.3524 14.0645 81.2144 14.4119 81.849 15.1066C82.4884 15.7967 82.8081 16.7065 82.8081 17.836C82.8081 18.9278 82.479 19.8399 81.8206 20.5725C81.167 21.3003 80.2766 21.6642 79.1493 21.6642ZM79.2133 20.6576C79.9474 20.6576 80.53 20.4142 80.961 19.9274C81.3967 19.4358 81.6146 18.7529 81.6146 17.8786C81.6146 17.0467 81.3967 16.3733 80.961 15.8581C80.53 15.3382 79.9474 15.0783 79.2133 15.0783C78.4792 15.0783 77.88 15.3406 77.4159 15.8652C76.9564 16.3851 76.7267 17.0657 76.7267 17.9069C76.7267 18.7907 76.9564 19.4713 77.4159 19.9486C77.88 20.4212 78.4792 20.6576 79.2133 20.6576Z"
        fill="white"
      />
      <path
        d="M93.7348 14.2347L91.5537 21.4941H90.346L88.8469 16.2976C88.809 16.1606 88.783 16.0708 88.7688 16.0283C88.7593 15.981 88.7451 15.903 88.7262 15.7943H88.6977C88.674 15.903 88.6527 15.9834 88.6338 16.0353C88.6196 16.0873 88.5935 16.17 88.5556 16.2835L86.9287 21.4941H85.7352L83.5328 14.2347H84.7548L86.2609 19.6013C86.2846 19.6816 86.3035 19.7572 86.3177 19.8281C86.3319 19.899 86.3485 19.9864 86.3675 20.0904H86.4101C86.4338 19.9817 86.4551 19.8943 86.474 19.8281C86.493 19.7572 86.5167 19.6769 86.5451 19.5871L88.2217 14.2347H89.3158L90.822 19.6154C90.8409 19.6816 90.8599 19.7549 90.8788 19.8352C90.8977 19.9108 90.9191 19.9959 90.9427 20.0904H90.9854C91.009 19.9959 91.0256 19.9226 91.0351 19.8706C91.0493 19.8139 91.073 19.7289 91.1061 19.6154L92.5839 14.2347H93.7348Z"
        fill="white"
      />
      <path
        d="M100.996 21.4941H99.8304V17.1838C99.8304 16.4702 99.6931 15.9408 99.4184 15.5958C99.1437 15.2461 98.734 15.0712 98.1893 15.0712C97.5736 15.0712 97.0739 15.2886 96.6902 15.7234C96.3113 16.1535 96.1219 16.697 96.1219 17.354V21.4941H94.9568V14.2347H96.1219V15.2697H96.1503C96.425 14.868 96.7613 14.5679 97.1591 14.3694C97.557 14.1661 98.0164 14.0645 98.5374 14.0645C99.3331 14.0645 99.9417 14.3221 100.363 14.8373C100.785 15.3477 100.996 16.0873 100.996 17.0562V21.4941Z"
        fill="white"
      />
      <path
        d="M103.056 21.4941V10.7467H104.221V21.4941H103.056Z"
        fill="white"
      />
      <path
        d="M109.556 21.6642C108.424 21.6642 107.539 21.3287 106.899 20.6576C106.26 19.9817 105.94 19.079 105.94 17.9495C105.94 16.7821 106.295 15.8439 107.006 15.135C107.716 14.4214 108.604 14.0645 109.67 14.0645C110.759 14.0645 111.621 14.4119 112.256 15.1066C112.896 15.7967 113.215 16.7065 113.215 17.836C113.215 18.9278 112.886 19.8399 112.228 20.5725C111.574 21.3003 110.684 21.6642 109.556 21.6642ZM109.62 20.6576C110.354 20.6576 110.937 20.4142 111.368 19.9274C111.804 19.4358 112.022 18.7529 112.022 17.8786C112.022 17.0467 111.804 16.3733 111.368 15.8581C110.937 15.3382 110.354 15.0783 109.62 15.0783C108.886 15.0783 108.287 15.3406 107.823 15.8652C107.364 16.3851 107.134 17.0657 107.134 17.9069C107.134 18.7907 107.364 19.4713 107.823 19.9486C108.287 20.4212 108.886 20.6576 109.62 20.6576Z"
        fill="white"
      />
      <path
        d="M120.085 21.4941H118.92V20.5299H118.892C118.645 20.8939 118.333 21.1751 117.954 21.3736C117.58 21.5673 117.165 21.6642 116.711 21.6642C115.986 21.6642 115.418 21.4705 115.006 21.0829C114.598 20.6954 114.395 20.2109 114.395 19.6296C114.395 18.949 114.608 18.4221 115.034 18.0487C115.46 17.6706 116.057 17.4367 116.824 17.3469L118.92 17.1058V16.645C118.92 16.1204 118.783 15.7281 118.508 15.4682C118.238 15.2035 117.866 15.0712 117.393 15.0712C116.943 15.0712 116.571 15.1728 116.277 15.376C115.984 15.5745 115.759 15.8534 115.602 16.2126L114.586 15.7943C114.823 15.2461 115.188 14.8207 115.68 14.5182C116.178 14.2158 116.77 14.0645 117.457 14.0645C118.304 14.0645 118.953 14.2914 119.403 14.7451C119.858 15.1941 120.085 15.825 120.085 16.6379V21.4941ZM118.92 18.545V18.0487L116.902 18.2756C116.452 18.3275 116.121 18.4575 115.908 18.6655C115.695 18.8687 115.588 19.1404 115.588 19.4807C115.588 19.8541 115.711 20.1542 115.958 20.3811C116.204 20.6032 116.526 20.7143 116.924 20.7143C117.521 20.7143 118.001 20.511 118.366 20.1046C118.735 19.6934 118.92 19.1735 118.92 18.545Z"
        fill="white"
      />
      <path
        d="M127.268 20.4307C126.998 20.8324 126.652 21.1396 126.231 21.3523C125.814 21.5603 125.35 21.6642 124.838 21.6642C123.82 21.6642 123.031 21.3405 122.472 20.693C121.918 20.0408 121.641 19.1546 121.641 18.0345C121.641 16.8057 121.956 15.8368 122.586 15.1279C123.216 14.419 124.038 14.0645 125.051 14.0645C125.544 14.0645 125.98 14.1543 126.358 14.3339C126.742 14.5135 127.045 14.7593 127.268 15.0712H127.296V10.7467H128.461V21.4941H127.296V20.4307H127.268ZM122.835 17.9778C122.835 18.8002 123.024 19.4524 123.403 19.9345C123.787 20.4165 124.315 20.6576 124.987 20.6576C125.698 20.6576 126.259 20.426 126.671 19.9628C127.088 19.4996 127.296 18.916 127.296 18.2118V17.1413C127.296 16.5647 127.095 16.0755 126.692 15.6738C126.295 15.2721 125.778 15.0712 125.144 15.0712C124.438 15.0712 123.877 15.3193 123.46 15.8156C123.043 16.3118 122.835 17.0326 122.835 17.9778Z"
        fill="white"
      />
      <path
        d="M137.207 21.6642C136.075 21.6642 135.189 21.3287 134.55 20.6576C133.91 19.9817 133.591 19.079 133.591 17.9495C133.591 16.7821 133.946 15.8439 134.656 15.135C135.367 14.4214 136.255 14.0645 137.321 14.0645C138.41 14.0645 139.272 14.4119 139.907 15.1066C140.546 15.7967 140.866 16.7065 140.866 17.836C140.866 18.9278 140.537 19.8399 139.878 20.5725C139.225 21.3003 138.334 21.6642 137.207 21.6642ZM137.271 20.6576C138.005 20.6576 138.588 20.4142 139.019 19.9274C139.454 19.4358 139.672 18.7529 139.672 17.8786C139.672 17.0467 139.454 16.3733 139.019 15.8581C138.588 15.3382 138.005 15.0783 137.271 15.0783C136.537 15.0783 135.938 15.3406 135.473 15.8652C135.014 16.3851 134.784 17.0657 134.784 17.9069C134.784 18.7907 135.014 19.4713 135.473 19.9486C135.938 20.4212 136.537 20.6576 137.271 20.6576Z"
        fill="white"
      />
      <path
        d="M148.624 21.4941H147.459V17.1838C147.459 16.4702 147.321 15.9408 147.047 15.5958C146.772 15.2461 146.362 15.0712 145.818 15.0712C145.202 15.0712 144.702 15.2886 144.319 15.7234C143.94 16.1535 143.75 16.697 143.75 17.354V21.4941H142.585V14.2347H143.75V15.2697H143.779C144.053 14.868 144.39 14.5679 144.787 14.3694C145.185 14.1661 145.645 14.0645 146.166 14.0645C146.961 14.0645 147.57 14.3221 147.992 14.8373C148.413 15.3477 148.624 16.0873 148.624 17.0562V21.4941Z"
        fill="white"
      />
      <path
        d="M157.874 21.0333C157.661 21.246 157.426 21.4019 157.17 21.5012C156.915 21.6004 156.614 21.6501 156.268 21.6501C155.695 21.6501 155.252 21.4917 154.94 21.1751C154.627 20.8537 154.471 20.3953 154.471 19.7998V15.2272H153.22V14.2347H154.471V12.5191L155.636 12.3135V14.2347H157.583V15.2272H155.636V19.7005C155.636 20.0455 155.702 20.296 155.835 20.452C155.967 20.6079 156.176 20.6859 156.46 20.6859C156.602 20.6859 156.737 20.6576 156.865 20.6008C156.998 20.5441 157.135 20.4354 157.277 20.2747L157.874 21.0333Z"
        fill="white"
      />
      <path
        d="M165.334 21.4941H164.168V17.1838C164.168 16.4702 164.031 15.9408 163.756 15.5958C163.486 15.2461 163.077 15.0712 162.527 15.0712C161.912 15.0712 161.412 15.2886 161.028 15.7234C160.649 16.1535 160.46 16.6994 160.46 17.361V21.4941H159.295V10.7467H160.46V15.2697H160.488C160.763 14.868 161.099 14.5679 161.497 14.3694C161.895 14.1661 162.354 14.0645 162.875 14.0645C163.671 14.0645 164.28 14.3197 164.701 14.8302C165.123 15.3406 165.334 16.0826 165.334 17.0562V21.4941Z"
        fill="white"
      />
      <path
        d="M173.354 18.07H168.104C168.104 18.9679 168.317 19.6249 168.744 20.0408C169.175 20.452 169.686 20.6576 170.278 20.6576C170.723 20.6576 171.114 20.5559 171.45 20.3527C171.791 20.1448 172.073 19.8376 172.296 19.4311L173.248 19.9132C172.992 20.4331 172.606 20.8561 172.09 21.1822C171.578 21.5035 170.948 21.6642 170.2 21.6642C169.234 21.6642 168.441 21.3452 167.82 20.7072C167.204 20.0691 166.896 19.131 166.896 17.8927C166.896 16.7915 167.209 15.8794 167.834 15.1563C168.459 14.4284 169.26 14.0645 170.236 14.0645C171.225 14.0645 171.993 14.3646 172.537 14.9649C173.082 15.5604 173.354 16.3922 173.354 17.4603V18.07ZM172.161 17.0987C172.161 16.4702 171.988 15.9763 171.642 15.6171C171.297 15.2532 170.821 15.0712 170.214 15.0712C169.627 15.0712 169.146 15.265 168.772 15.6525C168.403 16.0401 168.182 16.5221 168.111 17.0987H172.161Z"
        fill="white"
      />
      <path
        d="M65.0896 49.3219L72.6772 28.9898H76.4852L84.0159 49.3219H80.3784L78.6165 44.5012H70.4038L68.7129 49.3219H65.0896ZM71.3131 41.6371H77.6929L74.908 33.5695C74.8322 33.3616 74.7659 33.1772 74.7091 33.0165C74.6617 32.8464 74.6049 32.6384 74.5385 32.3927H74.4675C74.3917 32.6195 74.3302 32.818 74.2828 32.9882C74.2354 33.1583 74.1739 33.3521 74.0981 33.5695L71.3131 41.6371Z"
        fill="white"
      />
      <path
        d="M87.8682 47.8048H87.8114V56H84.5007V34.803H87.8114V36.8731H87.8682C88.446 36.0697 89.147 35.4694 89.9711 35.0724C90.8047 34.666 91.7378 34.4628 92.7703 34.4628C94.7027 34.4628 96.2088 35.1008 97.2887 36.3769C98.3686 37.6529 98.9085 39.43 98.9085 41.708C98.9085 44.09 98.3023 46.0136 97.0898 47.4787C95.8868 48.9343 94.2764 49.6622 92.2588 49.6622C91.321 49.6622 90.4827 49.5062 89.7438 49.1943C89.0144 48.8824 88.3892 48.4192 87.8682 47.8048ZM87.7688 41.4386V43.2251C87.7688 44.2932 88.124 45.1865 88.8344 45.9049C89.5543 46.6232 90.459 46.9824 91.5483 46.9824C92.7798 46.9824 93.746 46.5145 94.4469 45.5788C95.1574 44.643 95.5126 43.3811 95.5126 41.7931C95.5126 40.2523 95.1858 39.0944 94.5322 38.3193C93.8881 37.5348 92.9976 37.1425 91.8609 37.1425C90.6579 37.1425 89.6728 37.5442 88.9055 38.3477C88.1477 39.1511 87.7688 40.1814 87.7688 41.4386Z"
        fill="white"
      />
      <path
        d="M103.599 47.8048H103.542V56H100.232V34.803H103.542V36.8731H103.599C104.177 36.0697 104.878 35.4694 105.702 35.0724C106.536 34.666 107.469 34.4628 108.501 34.4628C110.434 34.4628 111.94 35.1008 113.02 36.3769C114.1 37.6529 114.64 39.43 114.64 41.708C114.64 44.09 114.033 46.0136 112.821 47.4787C111.618 48.9343 110.007 49.6622 107.99 49.6622C107.052 49.6622 106.214 49.5062 105.475 49.1943C104.745 48.8824 104.12 48.4192 103.599 47.8048ZM103.5 41.4386V43.2251C103.5 44.2932 103.855 45.1865 104.565 45.9049C105.285 46.6232 106.19 46.9824 107.279 46.9824C108.511 46.9824 109.477 46.5145 110.178 45.5788C110.888 44.643 111.244 43.3811 111.244 41.7931C111.244 40.2523 110.917 39.0944 110.263 38.3193C109.619 37.5348 108.729 37.1425 107.592 37.1425C106.389 37.1425 105.404 37.5442 104.636 38.3477C103.879 39.1511 103.5 40.1814 103.5 41.4386Z"
        fill="white"
      />
      <path
        d="M120.426 45.4795L123.367 44.0049C123.793 44.9218 124.371 45.6071 125.101 46.0608C125.84 46.5145 126.702 46.7414 127.687 46.7414C128.861 46.7414 129.766 46.4862 130.401 45.9758C131.045 45.4653 131.367 44.7517 131.367 43.8348C131.367 42.9463 131.049 42.2373 130.415 41.708C129.78 41.1787 128.804 40.758 127.488 40.4461C125.347 39.9451 123.798 39.2362 122.841 38.3193C121.885 37.393 121.406 36.15 121.406 34.5904C121.406 32.7282 122.055 31.2773 123.353 30.2375C124.66 29.1883 126.271 28.6637 128.184 28.6637C129.624 28.6637 130.893 28.9804 131.992 29.6137C133.091 30.2375 133.972 31.1686 134.635 32.4069L131.779 33.7964C131.343 32.9929 130.827 32.4163 130.23 32.0666C129.633 31.7168 128.909 31.542 128.056 31.542C126.995 31.542 126.171 31.783 125.584 32.2651C124.996 32.7377 124.703 33.371 124.703 34.165C124.703 34.959 124.973 35.5829 125.513 36.0366C126.053 36.4903 127.024 36.8778 128.426 37.1992C130.585 37.6813 132.163 38.4233 133.157 39.4253C134.161 40.4272 134.663 41.7742 134.663 43.4661C134.663 45.2715 134.062 46.7556 132.859 47.9182C131.656 49.0809 129.941 49.6622 127.715 49.6622C126.001 49.6622 124.504 49.2983 123.225 48.5704C121.956 47.8426 121.023 46.8123 120.426 45.4795Z"
        fill="white"
      />
      <path
        d="M143.702 48.3436C143.266 48.7595 142.759 49.0809 142.182 49.3077C141.604 49.5346 140.955 49.648 140.235 49.648C138.852 49.648 137.805 49.303 137.095 48.613C136.394 47.9135 136.043 46.8926 136.043 45.5504V37.3835H133.599V34.803H136.043V31.2442L139.34 30.6629V34.803H143.048V37.3835H139.34V45.437C139.34 46.0136 139.453 46.4389 139.681 46.713C139.918 46.9872 140.273 47.1242 140.746 47.1242C140.993 47.1242 141.234 47.077 141.471 46.9824C141.708 46.8879 141.959 46.7036 142.224 46.4295L143.702 48.3436Z"
        fill="white"
      />
      <path
        d="M151.092 49.6622C148.79 49.6622 146.962 48.9863 145.608 47.6346C144.253 46.283 143.576 44.4634 143.576 42.1759C143.585 39.8601 144.305 37.9979 145.735 36.5895C147.166 35.1717 149.022 34.4628 151.305 34.4628C153.598 34.4628 155.407 35.1575 156.733 36.547C158.059 37.9365 158.722 39.7325 158.722 41.9349C158.722 44.1845 158.021 46.0372 156.619 47.4929C155.227 48.9391 153.385 49.6622 151.092 49.6622ZM151.22 46.9824C152.527 46.9824 153.541 46.5382 154.261 45.6496C154.981 44.7517 155.341 43.5418 155.341 42.0199C155.341 40.5548 154.976 39.378 154.247 38.4895C153.527 37.5915 152.518 37.1425 151.22 37.1425C149.922 37.1425 148.885 37.6057 148.108 38.532C147.332 39.4489 146.943 40.6399 146.943 42.105C146.943 43.6268 147.327 44.8226 148.094 45.6922C148.861 46.5523 149.903 46.9824 151.22 46.9824Z"
        fill="white"
      />
      <path
        d="M168.344 37.7664C168.078 37.6624 167.804 37.582 167.519 37.5253C167.245 37.4592 166.951 37.4261 166.638 37.4261C165.663 37.4261 164.872 37.8609 164.266 38.7305C163.659 39.6001 163.356 40.7344 163.356 42.1334V49.3219H160.046V34.803H163.356V37.4261H163.413C163.754 36.4997 164.256 35.7908 164.919 35.2993C165.582 34.7983 166.35 34.5478 167.221 34.5478C167.59 34.5478 167.931 34.5762 168.244 34.6329C168.557 34.6896 168.893 34.7983 169.253 34.959L168.344 37.7664Z"
        fill="white"
      />
      <path
        d="M182 42.8706H171.954C171.983 44.2696 172.362 45.3235 173.091 46.0325C173.83 46.7319 174.734 47.0817 175.805 47.0817C176.563 47.0817 177.235 46.9068 177.823 46.5571C178.41 46.1979 178.907 45.6685 179.315 44.9691L181.858 46.3444C181.195 47.4031 180.347 48.2207 179.315 48.7973C178.291 49.3739 177.084 49.6622 175.691 49.6622C173.55 49.6622 171.836 49.0194 170.548 47.7339C169.259 46.4484 168.615 44.6004 168.615 42.1901C168.615 39.8931 169.274 38.031 170.59 36.6037C171.916 35.1764 173.574 34.4628 175.563 34.4628C177.609 34.4628 179.191 35.0724 180.309 36.2918C181.436 37.5111 182 39.2787 182 41.5946V42.8706ZM178.803 40.517C178.803 39.3827 178.519 38.5036 177.95 37.8798C177.382 37.2559 176.605 36.944 175.62 36.944C174.588 36.944 173.749 37.289 173.105 37.979C172.461 38.6596 172.073 39.5056 171.94 40.517H178.803Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7655_29320">
        <rect width="203.574" height="66.4017" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GooglePlayIcon = ({ isBlack }) => (
  <svg
    width="206"
    height="67"
    viewBox="0 0 206 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      width="204"
      height="66"
      rx="33"
      fill={isBlack ? '#1A1B1D' : '#4C4F53'}
      fillOpacity={isBlack ? '1' : '0.4'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6368 13.6046C24.2321 14.085 24 14.8251 24 15.7869V49.9872C24 50.9491 24.2321 51.6891 24.6521 52.1526L24.7619 52.2551L42.0915 33.092V32.6613L24.7466 13.5022L24.6368 13.6046Z"
      fill="url(#paint0_linear_7655_29317)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.8564 39.5045L42.0762 33.1109V32.6633L47.8582 26.2696L47.9832 26.3552L54.822 30.6531C56.7802 31.8735 56.7802 33.8847 54.822 35.1221L47.9832 39.42C47.9814 39.419 47.8564 39.5045 47.8564 39.5045Z"
      fill="url(#paint1_linear_7655_29317)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.9814 39.419L42.0762 32.8871L24.6368 52.1715C25.2737 52.9285 26.3459 53.014 27.544 52.274L47.9814 39.419Z"
      fill="url(#paint2_linear_7655_29317)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.9814 26.3571L27.544 13.5191C26.3477 12.7621 25.2737 12.8646 24.6368 13.6215L42.0744 32.8871L47.9814 26.3571Z"
      fill="url(#paint3_linear_7655_29317)"
    />
    <path
      d="M72.502 24.0021C72.0247 24.3598 71.4701 24.6461 70.8383 24.8608C70.2065 25.0754 69.5338 25.1828 68.8202 25.1828C67.4065 25.1828 66.2725 24.74 65.4179 23.8545C64.5634 22.9689 64.1361 21.7972 64.1361 20.3392C64.1361 18.8186 64.5611 17.5887 65.4111 16.6495C66.2656 15.7103 67.4043 15.2407 68.827 15.2407C69.6861 15.2407 70.4247 15.4129 71.0429 15.7572C71.6611 16.1016 72.1315 16.5846 72.4542 17.2063L71.4451 17.6625C71.1951 17.2152 70.8474 16.8753 70.402 16.6428C69.9565 16.4057 69.4315 16.2872 68.827 16.2872C67.777 16.2872 66.9315 16.6539 66.2906 17.3874C65.6543 18.1164 65.3361 19.0713 65.3361 20.252C65.3361 21.464 65.6565 22.4166 66.2975 23.1098C66.9429 23.7986 67.7974 24.1429 68.8611 24.1429C69.3293 24.1429 69.7792 24.0826 70.2111 23.9618C70.6429 23.8411 71.0247 23.6622 71.3565 23.4251V21.0771H69.0179V20.0574H72.502V24.0021Z"
      fill="white"
    />
    <path
      d="M79.8178 25.0218H74.636V15.4017H79.5996V16.4214H75.7815V19.6214H79.3133V20.6344H75.7815V24.0021H79.8178V25.0218Z"
      fill="white"
    />
    <path
      d="M87.2632 16.4214H84.4405V25.0218H83.2951V16.4214H80.4792V15.4017H87.2632V16.4214Z"
      fill="white"
    />
    <path d="M93.1677 25.0218H92.0223V15.4017H93.1677V25.0218Z" fill="white" />
    <path
      d="M101.309 16.4214H98.4859V25.0218H97.3404V16.4214H94.5245V15.4017H101.309V16.4214Z"
      fill="white"
    />
    <path
      d="M110.063 25.1828C108.681 25.1828 107.563 24.7355 106.709 23.8411C105.854 22.9421 105.427 21.7703 105.427 20.3258C105.427 18.7917 105.868 17.5618 106.749 16.636C107.636 15.7058 108.795 15.2407 110.227 15.2407C111.572 15.2407 112.659 15.6834 113.486 16.569C114.313 17.4545 114.727 18.6285 114.727 20.091C114.727 21.6071 114.297 22.8348 113.438 23.774C112.579 24.7132 111.454 25.1828 110.063 25.1828ZM110.063 24.1429C111.099 24.1429 111.936 23.7829 112.572 23.0629C113.209 22.3428 113.527 21.4036 113.527 20.2453C113.527 19.0511 113.215 18.094 112.593 17.374C111.97 16.6495 111.154 16.2872 110.145 16.2872C109.095 16.2872 108.245 16.6472 107.595 17.3673C106.949 18.0829 106.627 19.0355 106.627 20.2251C106.627 21.3924 106.943 22.3383 107.574 23.0629C108.211 23.7829 109.04 24.1429 110.063 24.1429Z"
      fill="white"
    />
    <path
      d="M124.395 25.0218H122.99L117.958 17.3539C117.886 17.2465 117.833 17.1705 117.802 17.1258C117.77 17.0766 117.727 17.0095 117.672 16.9245H117.631C117.64 17.014 117.647 17.1548 117.652 17.3472C117.656 17.535 117.658 17.7765 117.658 18.0717V25.0218H116.513V15.4017H117.999L122.895 22.9488C122.981 23.083 123.047 23.1903 123.093 23.2708C123.138 23.3469 123.19 23.4363 123.249 23.5392H123.277C123.268 23.405 123.261 23.2239 123.256 22.9958C123.252 22.7677 123.249 22.4949 123.249 22.1773V15.4017H124.395V25.0218Z"
      fill="white"
    />
    <path
      d="M79.6883 46.4277C78.8413 47.0447 77.86 47.5455 76.7443 47.9301C75.6285 48.3068 74.468 48.4951 73.2627 48.4951C70.5671 48.4951 68.4294 47.7138 66.8494 46.1513C65.2695 44.5887 64.4796 42.4853 64.4796 39.841C64.4796 37.1246 65.2695 34.921 66.8494 33.2303C68.4294 31.5315 70.4816 30.6821 73.0062 30.6821C74.6513 30.6821 76.0357 31.0026 77.1596 31.6437C78.2835 32.2767 79.1345 33.1421 79.7127 34.2399L77.2818 35.3938C76.8501 34.6486 76.2841 34.0957 75.5838 33.7351C74.8834 33.3745 74.0731 33.1942 73.1528 33.1942C71.4996 33.1942 70.1518 33.7751 69.1094 34.937C68.0751 36.0989 67.558 37.6615 67.558 39.6246C67.558 41.6359 68.0751 43.1985 69.1094 44.3123C70.1518 45.4261 71.524 45.983 73.2261 45.983C73.8858 45.983 74.5251 45.9028 75.144 45.7426C75.7629 45.5743 76.3086 45.3299 76.7809 45.0094V41.4396H72.8963V38.9756H79.6883V46.4277Z"
      fill="white"
    />
    <path
      d="M87.1292 48.4951C85.1502 48.4951 83.5784 47.9221 82.4139 46.7763C81.2493 45.6304 80.667 44.0879 80.667 42.1487C80.6752 40.1856 81.2941 38.607 82.5238 37.4131C83.7535 36.2111 85.3497 35.6101 87.3124 35.6101C89.2832 35.6101 90.8387 36.1991 91.9789 37.377C93.119 38.5549 93.6891 40.0774 93.6891 41.9444C93.6891 43.8515 93.0864 45.4221 91.8811 46.6561C90.684 47.8821 89.1 48.4951 87.1292 48.4951ZM87.2391 46.2234C88.363 46.2234 89.2344 45.8468 89.8533 45.0935C90.4722 44.3323 90.7817 43.3066 90.7817 42.0165C90.7817 40.7745 90.4682 39.7769 89.8411 39.0237C89.2222 38.2624 88.3548 37.8818 87.2391 37.8818C86.1234 37.8818 85.2317 38.2745 84.5639 39.0597C83.8961 39.837 83.5622 40.8466 83.5622 42.0887C83.5622 43.3787 83.892 44.3924 84.5516 45.1296C85.2113 45.8588 86.1071 46.2234 87.2391 46.2234Z"
      fill="white"
    />
    <path
      d="M100.483 48.4951C98.5036 48.4951 96.9318 47.9221 95.7672 46.7763C94.6027 45.6304 94.0204 44.0879 94.0204 42.1487C94.0285 40.1856 94.6475 38.607 95.8772 37.4131C97.1069 36.2111 98.7031 35.6101 100.666 35.6101C102.637 35.6101 104.192 36.1991 105.332 37.377C106.472 38.5549 107.042 40.0774 107.042 41.9444C107.042 43.8515 106.44 45.4221 105.235 46.6561C104.037 47.8821 102.453 48.4951 100.483 48.4951ZM100.592 46.2234C101.716 46.2234 102.588 45.8468 103.207 45.0935C103.826 44.3323 104.135 43.3066 104.135 42.0165C104.135 40.7745 103.822 39.7769 103.194 39.0237C102.576 38.2624 101.708 37.8818 100.592 37.8818C99.4768 37.8818 98.585 38.2745 97.9172 39.0597C97.2494 39.837 96.9155 40.8466 96.9155 42.0887C96.9155 43.3787 97.2454 44.3924 97.905 45.1296C98.5647 45.8588 99.4605 46.2234 100.592 46.2234Z"
      fill="white"
    />
    <path
      d="M119.773 47.4974C119.773 49.5728 119.199 51.1754 118.05 52.3052C116.91 53.4351 115.326 54 113.298 54C112.004 54 110.876 53.7716 109.915 53.3149C108.954 52.8662 108.147 52.2211 107.496 51.3797L109.56 49.8653C110.033 50.5143 110.566 50.9991 111.161 51.3196C111.763 51.6402 112.488 51.8004 113.335 51.8004C114.467 51.8004 115.347 51.4799 115.974 50.8389C116.609 50.1978 116.927 49.2443 116.927 47.9782V46.5599H116.878C116.422 47.1929 115.864 47.6737 115.204 48.0023C114.553 48.3308 113.742 48.4951 112.773 48.4951C111.144 48.4951 109.837 47.9582 108.852 46.8844C107.866 45.8027 107.374 44.2962 107.374 42.3651C107.374 40.2497 107.936 38.595 109.06 37.401C110.192 36.2071 111.609 35.6101 113.311 35.6101C114.052 35.6101 114.736 35.7463 115.363 36.0188C115.99 36.2912 116.495 36.6839 116.878 37.1967H116.927V35.8986H119.773V47.4974ZM116.951 42.4853V41.055C116.951 40.1735 116.65 39.4243 116.047 38.8073C115.444 38.1903 114.667 37.8818 113.714 37.8818C112.655 37.8818 111.816 38.2584 111.197 39.0117C110.578 39.7649 110.269 40.8386 110.269 42.2329C110.269 43.4749 110.554 44.4525 111.124 45.1656C111.702 45.8708 112.5 46.2234 113.518 46.2234C114.536 46.2234 115.363 45.8788 115.998 45.1897C116.633 44.5006 116.951 43.5991 116.951 42.4853Z"
      fill="white"
    />
    <path d="M121.704 48.2066V29.985H124.551V48.2066H121.704Z" fill="white" />
    <path
      d="M137.208 42.7377H128.571C128.596 43.9236 128.921 44.8171 129.548 45.4181C130.184 46.011 130.961 46.3075 131.882 46.3075C132.533 46.3075 133.111 46.1593 133.616 45.8628C134.121 45.5583 134.549 45.1096 134.899 44.5166L137.086 45.6825C136.516 46.5799 135.787 47.2731 134.899 47.7619C134.019 48.2507 132.981 48.4951 131.784 48.4951C129.943 48.4951 128.469 47.9502 127.362 46.8604C126.254 45.7706 125.7 44.2041 125.7 42.1608C125.7 40.2136 126.266 38.635 127.398 37.4251C128.539 36.2151 129.964 35.6101 131.674 35.6101C133.433 35.6101 134.793 36.127 135.754 37.1606C136.723 38.1943 137.208 39.6928 137.208 41.6559V42.7377ZM134.459 40.7425C134.459 39.7809 134.215 39.0357 133.726 38.5068C133.238 37.978 132.57 37.7135 131.723 37.7135C130.835 37.7135 130.114 38.006 129.561 38.591C129.007 39.1679 128.673 39.8851 128.559 40.7425H134.459Z"
      fill="white"
    />
    <path
      d="M145.884 42.2088V48.2066H142.977V30.9706H148.376C150.387 30.9706 151.931 31.4353 153.006 32.3649C154.089 33.2864 154.63 34.6165 154.63 36.3553C154.63 38.1262 154.028 39.5445 152.822 40.6103C151.617 41.676 149.992 42.2088 147.948 42.2088H145.884ZM145.884 33.3144V39.853H147.814C149.027 39.853 149.956 39.5605 150.599 38.9756C151.251 38.3826 151.576 37.5493 151.576 36.4755C151.576 35.4419 151.271 34.6566 150.66 34.1197C150.058 33.5828 149.162 33.3144 147.973 33.3144H145.884Z"
      fill="white"
    />
    <path d="M155.548 48.2066V29.985H158.394V48.2066H155.548Z" fill="white" />
    <path
      d="M169.903 48.2066H167.155V46.6801H167.106C166.666 47.2731 166.128 47.7258 165.493 48.0383C164.858 48.3428 164.15 48.4951 163.368 48.4951C162.211 48.4951 161.262 48.1705 160.521 47.5215C159.788 46.8724 159.422 46.023 159.422 44.9733C159.422 43.8435 159.788 42.938 160.521 42.2569C161.262 41.5678 162.321 41.1471 163.698 40.9949L167.167 40.6103V40.0213C167.167 39.2601 166.951 38.6911 166.519 38.3145C166.088 37.9299 165.522 37.7376 164.821 37.7376C164.129 37.7376 163.547 37.8898 163.075 38.1943C162.602 38.4988 162.211 38.9355 161.902 39.5045L159.776 38.4347C160.2 37.6094 160.868 36.9323 161.78 36.4034C162.692 35.8746 163.775 35.6101 165.029 35.6101C166.609 35.6101 167.814 36.0188 168.645 36.8361C169.484 37.6454 169.903 38.8233 169.903 40.3699V48.2066ZM167.167 43.2065V42.5334L164.15 42.8579C163.457 42.93 162.952 43.1183 162.635 43.4228C162.317 43.7273 162.158 44.136 162.158 44.6488C162.158 45.1697 162.337 45.5943 162.696 45.9229C163.062 46.2434 163.535 46.4037 164.113 46.4037C165.025 46.4037 165.762 46.1032 166.324 45.5022C166.886 44.8932 167.167 44.128 167.167 43.2065Z"
      fill="white"
    />
    <path
      d="M183 35.8986L177.185 50.6586C176.754 51.7564 176.171 52.5857 175.438 53.1466C174.714 53.7155 173.83 54 172.788 54C172.283 54 171.806 53.9439 171.358 53.8317C170.919 53.7276 170.499 53.5593 170.1 53.3269L171.334 51.3557C171.586 51.4999 171.814 51.6001 172.018 51.6562C172.222 51.7203 172.433 51.7523 172.653 51.7523C173.036 51.7523 173.362 51.6562 173.63 51.4639C173.907 51.2796 174.156 50.939 174.376 50.4422L175.292 48.3508L170.381 35.8986H173.484L176.355 44.0599C176.444 44.3083 176.513 44.5286 176.562 44.7209C176.611 44.9132 176.668 45.1496 176.733 45.4301H176.794C176.851 45.1496 176.908 44.9172 176.965 44.7329C177.031 44.5486 177.104 44.3323 177.185 44.0839L180.08 35.8986H183Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7655_29317"
        x1="273.737"
        y1="1.11004"
        x2="203.499"
        y2="141.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#047ED6" />
        <stop offset="1" stopColor="#50E6FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7655_29317"
        x1="195.101"
        y1="33.4954"
        x2="-21.7729"
        y2="33.4954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA1C" />
        <stop offset="1" stopColor="#FEB705" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7655_29317"
        x1="161.095"
        y1="20.3133"
        x2="138.993"
        y2="131.535"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9414F" />
        <stop offset="1" stopColor="#8C193F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_7655_29317"
        x1="-5.92004"
        y1="-9.21556"
        x2="3.94583"
        y2="40.4634"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33C481" />
        <stop offset="1" stopColor="#61E3A7" />
      </linearGradient>
    </defs>
  </svg>
);
