import { Link, useLocation } from 'react-router-dom';
import facebook from '../../assets/images/socialIcons/facebook.svg';
import whatsApp from '../../assets/images/socialIcons/whatsApp.svg';
import instagram from '../../assets/images/socialIcons/instagram.svg';
import vk from '../../assets/images/socialIcons/vk.svg';
import youtube from '../../assets/images/socialIcons/youtube.svg';
import './style.css';
import { useContext } from 'react';
import { TranslationsContext } from '../../context/TranslationsContext';

export const MainFooter = () => {
  const location = useLocation();

  const { mainLang, fullTranslations } = useContext(TranslationsContext);

  return (
    <footer className="main-footer">
      <div className="main-footer-top container">
        <div className="main-footer-links">
          <Link
            to={`/${mainLang}/pp`}
            className={`main-footer-links-item main-footer-links-item_underline ${location.pathname.includes('/pp') ? 'active' : ''}`}
          >
            {fullTranslations?.footer?.privacyPolicy}
          </Link>
          <a href="mailto:info@mykin.ru" className="main-footer-links-item">
            info@mykin.ru
          </a>
          <Link
            to={`/${mainLang}/ua`}
            className={`main-footer-links-item main-footer-links-item_underline ${location.pathname.includes('/ua') ? 'active' : ''}`}
          >
            {fullTranslations?.footer?.userAgreement}
          </Link>
        </div>
        <div className="main-footer-socials">
          <a
            href="https://www.facebook.com/mykin2020"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="Facebook" />
          </a>
          <a
            href="https://wa.me/message/J3PM72TSW4EQB1"
            target="_blank"
            rel="noreferrer"
          >
            <img src={whatsApp} alt="WhatsApp" />
          </a>
          <a
            href="https://www.instagram.com/m_y_k_i_n/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="https://vk.com/mykin" target="_blank" rel="noreferrer">
            <img src={vk} alt="Vk" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCSKOZuqzYM9TF_w-Vaiy-aQ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="Youtube" />
          </a>
        </div>
      </div>
      <div className='main-footer-bottom'>
        <div className="container">© 2023 MYKIN</div>
      </div>
    </footer>
  );
};
