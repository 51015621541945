export class TreeAdapter {
    nodeList;
    rootNode;
    positioned;
    maxRelative;
    minRelative;

    constructor(personArray) {
        this.personArray = personArray;
        this.nodeList = [];

        for (let i = 0; i < this.personArray.length; i++) {
            this.nodeList.push(new TreeNode(this.personArray[i]));
        }

        this.rootNode = this.nodeList[0];

        let algorithm = new FamilyTreeAlgorithm();
        algorithm.calculateTree(this.nodeList, this.rootNode);

        this.positioned = true;
        this.maxRelative = algorithm.maxRelative;
        this.minRelative = algorithm.minRelative;
    }

    getNode(position) {
        if (this.nodeList.length === 0) return null;
        return this.nodeList[position];
    }

    getMinLevel() {
        let minLevel = Number.MAX_VALUE

        for (let index = 0; index < this.nodeList.length; index++) {
            if (this.nodeList[index].level < minLevel) minLevel = this.nodeList[index].level
        }
        return minLevel
    }

    getRelativeHeight() {
        let maxLevel = Number.MIN_VALUE
        let minLevel = Number.MAX_VALUE
        
        for (let index = 0; index < this.nodeList.length; index++) {
            if (this.nodeList[index].level > maxLevel)
                maxLevel = this.nodeList[index].level
            
            if (this.nodeList[index].level < minLevel)
                minLevel = this.nodeList[index].level
        }
        return maxLevel + Math.abs(minLevel) + 1
    }

    findClickedElement(x, y, nodeSize) {
        for (let index = 0; index < this.nodeList.length; index++) {
            let node = this.nodeList[index]

            if (x > node.xLeft && x < node.xLeft + nodeSize) {
                if (y > node.yTop && y < node.yTop + nodeSize) {
                    return node
                }
            }
        }
        return null
    }
}

export class TreeNode {

    constructor(person) {
        this.person = person;
    }

    level = null;
    relative = null;

    xLeft = 0;
    yTop = 0;

    width = 0;
    height = 0;

    left = true;

    setSize(width, height) {
        this.width = width;
        this.height = height;
    }

    toString() {
        return this.person.toString();
    }
}

export class FamilyTreeAlgorithm {
    nodeList;
    rootNode;

    maxRelative = 0.0;
    minRelative = 0.0;

    calculateTree(nodeList, rNode) {
        this.nodeList = nodeList;
        this.rootNode = rNode;

        this.rootNode.level = 0;
        this.rootNode.relative = 0.0;

        try {
            this.walk(this.rootNode, 0, 0.0, true);
        } catch (error) {
            return [this.rootNode];
        }

        return this.nodeList;
    }


    visited = [];

    walk(
        toTreeNode,
        level,
        _relative,
        calculateParent
    ) {
        if (this.maxRelative < _relative) this.maxRelative = _relative
        if (this.minRelative > _relative) this.minRelative = _relative

        let relative = _relative
        let person = toTreeNode?.person || {}
        let node = this.getTreeNodeByPerson(person?.personId) || {}

        node.level = level 
        node.relative = relative

        if (person?.familyTree?.length) {
            person.parentId = null
            person.spouseId = null
            person.descendantIdsList = []
        }

        let spouseHasParent
        if (person?.spouseId != null) {
            spouseHasParent = this.getPersonByName(person.spouseId)?.parentId != null
        } else spouseHasParent = false


        this.visited.push(person.personId)

        if (person?.descendantIdsList?.length != 0) {
            let childRelative

            if (node?.left && this.rootNode.person.parentId != person.personId) {
                if (person.descendantIdsList.length == 1) {
                    childRelative = relative - TreeViewIndents.parentIndentOneChild
                } else childRelative = relative - TreeViewIndents.parentIndent
            } else {
                if (person?.descendantIdsList?.length == 1) {
                    childRelative = relative + TreeViewIndents.parentIndentOneChild
                } else childRelative = relative + TreeViewIndents.parentIndent
            }

            let child = this.findFirstUnvisited((person?.descendantIdsList))
            if (child != null) {
                let childNode = this.getTreeNodeByPerson(child.personId)
                childNode.left = node.left

                this.walk(childNode, level + 1, childRelative, true)
            }
        }

        if (person?.spouseId != null && !this.visited.includes(person?.spouseId) && !spouseHasParent) {
            let spouseNode = this.getTreeNodeByPerson(person.spouseId) || {}

            let spouseRelative

            if (node.left
                || person.descendantIdsList.includes(this.rootNode.person.personId)
                || person == this.rootNode.person
            ) {
                if (this.calculateDescendant(person.descendantIdsList) > 1) {
                    spouseRelative = this.findMinRelative(person.descendantIdsList) - TreeViewIndents.parentIndent
                } else spouseRelative = relative - TreeViewIndents.spouseIndent
            } else {
                if (this.calculateDescendant(person.descendantIdsList) > 1) {
                    spouseRelative = this.findMaxRelative(person.descendantIdsList) + TreeViewIndents.parentIndent
                } else spouseRelative = relative + TreeViewIndents.spouseIndent
            }


            spouseNode.left = node.left
            if (person.descendantIdsList.includes(this.rootNode.person.personId)) spouseNode.left = true
            else relative = spouseRelative

            this.walk(
                spouseNode, level,
                spouseRelative, true
            )
        }

        if (person?.parentId != null) {
            let parent = this.getPersonByName(person.parentId)
            if (parent?.descendantIdsList?.length > 1) {
                let bro = this.findFirstUnvisited(parent.descendantIdsList)
                if (bro != null && !this.visited.includes(bro.personId)) {

                    if (node.left) {
                        relative += -TreeViewIndents.brotherIndent
                    } else relative += TreeViewIndents.brotherIndent

                    let broNode = this.getTreeNodeByPerson(bro.personId)
                    broNode.left = node.left
                    this.walk(broNode, level, relative, false)
                }
            }

            if (calculateParent) {
                let parentNode = this.getTreeNodeByPerson(parent?.personId) || {}
                let parentRelative

                if (node.left) {
                    if (parent?.descendantIdsList?.length == 1) parentRelative = _relative + TreeViewIndents.parentIndentOneChild
                    else parentRelative = _relative + TreeViewIndents.parentIndent
                } else {
                    if (parent?.descendantIdsList?.length == 1) parentRelative = _relative - TreeViewIndents.parentIndentOneChild
                    else parentRelative = _relative - TreeViewIndents.parentIndent
                }

                if (!this.visited.includes(person.parentId)) {
                    parentNode.left = node.left
                    if (person == this.rootNode.person) {
                        parentNode.left = false
                    }

                    this.walk(parentNode, level - 1, parentRelative, true)
                }
            }
        }

        if (spouseHasParent && person?.spouseId != null && !this.visited.includes(person.spouseId)) {
            let spouseNode = this.getTreeNodeByPerson(person.spouseId)
            let spouseRelative
            if (node.left
                || person.descendantIdsList.includes(this.rootNode.person.personId)
                || person == this.rootNode.person
            ) {
                if (this.calculateDescendant(person.descendantIdsList) > 1) {
                    spouseRelative = this.findMinRelative(person.descendantIdsList) - TreeViewIndents.parentIndent
                } else spouseRelative = relative - TreeViewIndents.spouseIndent
            } else {
                if (this.calculateDescendant(person.descendantIdsList) > 1) {
                    spouseRelative = this.findMaxRelative(person.descendantIdsList) + TreeViewIndents.parentIndent
                } else spouseRelative = relative + TreeViewIndents.spouseIndent
            }

            spouseNode.left = node.left
            if (person.descendantIdsList.includes(this.rootNode.person.personId)) spouseNode.left = true

            let relativeBetweenSpouse

            if (spouseNode.left != node.left) {
                relativeBetweenSpouse = node.relative - this.calculateParentRelativeInDepth(node, spouseNode)
            } else {
                relativeBetweenSpouse = this.calculateParentRelativeInDepth(node, spouseNode)
            }

            if ((spouseNode.left && relativeBetweenSpouse < spouseRelative)
                || !spouseNode.left && relativeBetweenSpouse > spouseRelative
            ) {
                spouseRelative =
                    relativeBetweenSpouse

                if (!this.descendantHaveChildren(person.descendantIdsList)) {
                    this.shiftChildren(
                        person.descendantIdsList,
                        Math.min(spouseRelative, node.relative),
                        Math.max(spouseRelative, node.relative)
                    )
                }
            }

            //check if brothers and sisters may have children
            if (person.parentId != null) {
                let parent = this.getPersonByName(person.parentId)

                let intervalMin = (Math.min(node.relative, spouseRelative))
                let intervalMax = (Math.max(node.relative, spouseRelative))

                for (let i = 0; i < parent.descendantIdsList.length; i++) {
                    let childNode = this.getTreeNodeByPerson(parent.descendantIdsList[i])

                    if (childNode.relative > intervalMin && childNode.relative < intervalMax) {
                        childNode.person.mayHavChildren = false
                    }
                }
            }

            this.walk(
                spouseNode, level,
                spouseRelative, true
            )
        }
    }

    descendantHaveChildren(descendantList) {

        for (let i = 0; i < descendantList.length; i++) {
            let childId = descendantList[i]
            let child = this.getPersonByName(childId)

            if (child.descendantIdsList.length != 0) return true

            let childSpouse = this.nodeList.find(element => element.person.spouseId == child.personId)

            if (childSpouse != null && childSpouse.person.descendantIdsList.length != 0) return true
        }
        return false
    }

    shiftChildren(
        descendantNameList,
        startRelative,
        endRelative
    ) {
        let countChildren = this.calculateDescendant(descendantNameList);
        let spaceForOneChild = (Math.abs(startRelative) + Math.abs(endRelative)) / countChildren;

        let index = 0;

        for (let i = 0; i < descendantNameList.length; i++) {
            let childNode = this.getTreeNodeByPerson(descendantNameList[i]);
            childNode.relative =
                startRelative + (spaceForOneChild / 2 + spaceForOneChild * index);
            index++;

            if (childNode.person.spouseId != null) {
                let spouseNode = this.getTreeNodeByPerson(childNode.person.spouseId);
                spouseNode.relative = startRelative + (spaceForOneChild / 2 + spaceForOneChild * index);
                index++;
            }
        }
    }

    calculateParentRelativeInDepth(node, spouseNode) {
        let spouseParentName = spouseNode.person.parentId
        let numberOfGenerations = 0
        while (spouseParentName != null) {
            let person = this.getPersonByName(spouseParentName)
            numberOfGenerations++
            spouseParentName = person.parentId

            if (spouseParentName == null) {
                let spouse = this.getPersonByName(person?.spouseId)
                if (spouse != null) spouseParentName = spouse.parentId
            }
        }

        let parentName = node.person.parentId
        let numberOfNodeGenerations = 0
        while (parentName != null) {
            let person = this.getPersonByName(parentName)
            numberOfNodeGenerations++
            parentName = person.parentId
            if (parentName == null) {
                let spouse = this.getPersonByName(person?.spouseId)
                if (spouse != null) parentName = spouse.parentId
            }
        }

        if (node.left && spouseNode.left) {
            return this.findMinAncestor(
                node,
                spouseNode
            ) - TreeViewIndents.spouseIndent - Math.max(
                numberOfGenerations,
                numberOfNodeGenerations
            ) * TreeViewIndents.parentIndentOneChild
        } else if (!node.left && !spouseNode.left) {
            return this.findMaxAncestor(
                node,
                spouseNode
            ) + TreeViewIndents.spouseIndent + Math.max(
                numberOfGenerations,
                numberOfNodeGenerations
            ) * TreeViewIndents.parentIndentOneChild
        } else {
            return TreeViewIndents.parentIndentOneChild * (numberOfNodeGenerations + numberOfGenerations) + TreeViewIndents.brotherIndent
        }

    }

    findMinAncestor(node, spouseNode) {
        let parent
        if (((node.relative) < (spouseNode.relative)) ||
            (node.relative != null && spouseNode.relative == null)
        ) {
            if (node.person.parentId != null) parent = this.getTreeNodeByPerson(node.person.parentId)
            else if (spouseNode.person.parentId != null) parent = this.getTreeNodeByPerson(spouseNode.person.parentId)
            else return node.relative
        } else {
            if (spouseNode.person.parentId != null) parent = this.getTreeNodeByPerson(spouseNode.person.parentId)
            else if (node.person.parentId != null) parent = this.getTreeNodeByPerson(node.person.parentId)
            else return spouseNode.relative
        }

        let parentSpouse = this.getTreeNodeByPerson(parent.person.spouseId)

        if (parentSpouse != null) return this.findMinAncestor(parent, parentSpouse)
        else return parent.relative
    }

    findMaxAncestor(node, spouseNode) {
        let parent
        if ((node.relative > spouseNode.relative) || (node.relative != null && spouseNode.relative == null)) {
            if (node.person.parentId != null) parent = this.getTreeNodeByPerson(node.person.parentId)
            else if (spouseNode.person.parentId != null) parent = this.getTreeNodeByPerson(spouseNode.person.parentId)
            else return node.relative
        } else {

            if (spouseNode.person.parentId != null) parent = this.getTreeNodeByPerson(spouseNode.person.parentId)
            else if (node.person.parentId != null) parent = this.getTreeNodeByPerson(node.person.parentId)
            else return spouseNode.relative

        }

        let parentSpouse = this.getTreeNodeByPerson(parent.person.spouseId)

        if (parentSpouse != null) return this.findMaxAncestor(parent, parentSpouse)
        else return parent.relative
    }

    calculateDescendant(descendantList) {
        let count = 0

        for (let i = 0; i < descendantList.length; i++) {
            count++
            let person = this.getPersonByName(descendantList[i])
            if (person != null && person.spouseId != null && this.getPersonByName(person.spouseId).parentId == null) count++
        }
        return count
    }


    findMaxRelative(arrayList) {
        let max = Number.MIN_VALUE

        for (let i = 0; i < arrayList.length; i++) {
            let person = this.getPersonByName(arrayList[i])
            if (person != null && person.spouseId != null) {
                let currentRelative = this.getTreeNodeByPerson(person.spouseId).relative
                if (currentRelative > max) max = currentRelative
            }

            let currentRelative = this.getTreeNodeByPerson(arrayList[i])?.relative
            if (currentRelative > max) max = currentRelative
        }

        return max
    }

    findMinRelative(arrayList) {
        let min = Number.MAX_VALUE

        for (let i = 0; i < arrayList.length; i++) {
            let person = this.getPersonByName(arrayList[i])
            if (person?.spouseId != null) {
                let currentRelative = this.getTreeNodeByPerson(person.spouseId).relative
                if (currentRelative < min) min = currentRelative
            }

            let currentRelative = this.getTreeNodeByPerson(arrayList[i])?.relative
            if (currentRelative < min) min = currentRelative
        }

        return min
    }


    findFirstUnvisited(personList) {
        for (let i = 0; i < personList?.length; i++) {
            if (!this.visited.includes(personList[i])) return this.getPersonByName(personList[i])
        }

        return null
    }

    getTreeNodeByPerson(personId) {
        return this.nodeList.find(element => element.person.personId == personId)
    }

    getTreeNodeByPersonId(personId) {
        return this.nodeList.find(element => element.person.personId == personId)
    }

    getPersonByName(personId) {
        return this.nodeList.find(element => element.person.personId == personId)?.person
    }
}

export class TreeViewIndents {

    static DEFAULT_SIBLING_SEPARATION = 100 //scaleFactor in drawTree
    static DEFAULT_SUBTREE_SEPARATION = 100
    static INDENT_BETWEEN_SPOUSES = 100

    static spouseIndent = 1.0
    static brotherIndent = 1.0
    static parentIndent = 1 / 3.0
    static parentIndentOneChild = 0.5
}
