import { useParams } from 'react-router';
import { Footer } from './components/Footer';
import { TreeHeader } from './components/TreeHeader';
import './style.css';
import { useContext, useEffect, useState } from 'react';
import { getTreeData } from '../../api/api';
import { drawTree } from '../../functions/drawTree';
import { personArray } from '../../functions/testTree';
import { Modal } from './components/Modal';
import { Spinner } from '../../components/Loaders/Spinner';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import { TreeHelmet } from './components/TreeHelmet';
import { TranslationsContext } from '../../context/TranslationsContext';

const statusError = {
  403: {en: 'Tree hidden', ru: 'Дерево скрыто', pt: 'Árvore escondida', es: 'Árbol oculto'},
  404: {en: 'Tree not found', ru: 'Дерево не найдено', pt: 'Árvore não encontrada', es: 'Árbol no encontrado'},
  500: {en: 'Server error', ru: 'Ошибка сервера', pt: 'Erro do servidor', es: 'Error del servidor'},
};

const Tree = () => {
  const { userId, treeId } = useParams();
  //http://localhost:3000/tree/iKm52lhmtBebtN00HiPIbNhcdr02/1683978189388

  const { fullTranslations, mainLang } = useContext(TranslationsContext);

  const [treeData, setTreeData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [personData, setPersonData] = useState({});

  const openModal = (person) => {
    setModalIsOpened(true);
    setPersonData(person);
  };
  useEffect(() => {
    if ($('.canvas')) {
      $('.canvas').draggable();
    }
  }, []);

  useEffect(() => {
    const getTree = async () => {
      try {
        setIsLoading(true);
        const data = await getTreeData(userId, treeId);
        setTreeData(data);
        drawTree(data?.data, openModal);
        // console.log(personArray)
        // drawTree(personArray, openModal);
        // console.log(data?.data);
      } catch (e) {
        if (+e?.response?.status >= 500) {
          setErrorMessage(statusError[500]);
        } else {
          setErrorMessage(statusError[e?.response?.status]);
        }
      } finally {
        setIsLoading(false);
      }
    };
    userId && treeId && getTree();
  }, [userId, treeId]);

  return (
    <>
      <TreeHelmet />

      <TreeHeader title={treeData?.name} />
      <main className="root_tree">
        {errorMessage ? (
          <h2 className="error__title">{errorMessage?.[mainLang]}</h2>
        ) : (
          <div className="root__main">
            {isLoading && <Spinner />}
            {treeData?.data?.length === 1 ? (
              <div className="tree_empty">
                {fullTranslations?.tree?.emptyTree}
              </div>
            ) : (
              <div className="canvas-container">
                <canvas className="canvas" id="canvas">
                  {fullTranslations?.tree?.updateBrowser}
                </canvas>
              </div>
            )}

            {/* 
{#        <div className="root__scale">#}
{#            <div className="scale" data-scale="1">#}
{#                <span className="scale--button scale-plus"></span>#}
{#                <span className="scale--button scale-minus"></span>#}
{#            </div>#}
{#        </div>#} */}
          </div>
        )}
      </main>
      <Footer />
      <Modal
        modalIsOpened={modalIsOpened}
        setModalIsOpened={setModalIsOpened}
        personData={personData}
      />
    </>
  );
};

export default Tree;
