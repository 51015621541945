import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { useContext } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';

export const SliderMain = () => {
  const { fullTranslations } = useContext(TranslationsContext);

  return (
    <Swiper
      slidesPerView={1}
      // initialSlide={initialSlide}
      // zoom={true}
      autoplay={{
        delay: 5000,
        pauseOnMouseEnter: true,
      }}
      centeredSlides={true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      modules={[Pagination, Autoplay]}
      className={'slider-main'}
    >
      {fullTranslations?.main?.slider?.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};
