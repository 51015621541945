import { ReactComponent as PlayIcon } from "../../assets/images/intro/intro-play-icon.svg";
import Video from '../../assets/video/intro.mp4';
import './style.css';

const VideoBlock = () => {
  const videoLink = 'https://youtu.be/5D0G10CYsVE'
  return (
    <div className={'video-block'}>
      <div className={'video-block-video-container'}>
        <video autoPlay muted loop className={'video-block-video'} playsInline>
          <source src={Video} type="video/mp4"/>
          Your browser does not support HTML5 video.
        </video>
      </div>
      <p className={'video-block-title'}>Цифровой<br/> аватар предка</p>
      <p className={'video-block-subtitle'}>через приложение</p>
      <button
        className={'video-block-button'}
        onClick={() => window.open(videoLink)}>
        <PlayIcon className={'video-block-button-icon'}/>
        Смотреть видео
      </button>
    </div>
  )
}

export default VideoBlock;
