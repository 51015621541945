import { useContext } from 'react';
import { TranslationsContext } from '../../context/TranslationsContext';
import './style.css';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

export const BurgerMenu = ({ openLangModal, logOutFromProfile }) => {
  const { isAuthState } = useContext(AuthContext);
  const { mainLang, profileTranslations, fullTranslations } =
    useContext(TranslationsContext);
  const location = useLocation();

  const isProfile =
    location.pathname.includes('/profile') ||
    location.pathname.includes('/payments-history');

  return (
    <div className="burger-menu">
      {isProfile ? (
        <>
          <button
            onClick={openLangModal}
            type="button"
            id="lang1"
            className="burger-menu-item"
          >
            {profileTranslations?.header?.changeLang}
          </button>
          <button
            onClick={logOutFromProfile}
            type="button"
            id="logout"
            className="burger-menu-item"
          >
            {profileTranslations?.header?.logout}
          </button>
        </>
      ) : isAuthState ? (
        <>
          <CommonBurgerLinks openLangModal={openLangModal} />
          <Link to="/profile" className="burger-menu-item" id="myProfile">
            {fullTranslations?.header?.myProfile}
          </Link>
        </>
      ) : (
        <>
          <CommonBurgerLinks openLangModal={openLangModal} />

          <Link to={`/${mainLang}/login`} className="burger-menu-item" id="myProfile">
            {fullTranslations?.header?.login}
          </Link>
        </>
      )}
    </div>
  );
};

const CommonBurgerLinks = ({ openLangModal }) => {
  const { fullTranslations, mainLang } = useContext(TranslationsContext);
  const location = useLocation();

  return (
    <>
      <Link to={`/${mainLang}`} className={`burger-menu-item ${location?.pathname === ('/') || location?.pathname === (`/${mainLang}`) ? 'active' : ''}`} id="aboutUs">
        {fullTranslations?.header?.aboutUs}
      </Link>
      <Link
        to={`/${mainLang}/services/time-capsule`}
        className={`burger-menu-item ${location.pathname.includes('/services') ? 'active' : ''}`}
        id="services"
      >
        {fullTranslations?.header?.services}
      </Link>
      <button
        onClick={openLangModal}
        type="button"
        id="lang"
        className="burger-menu-item"
      >
        {fullTranslations?.header?.changeLang}
      </button>
    </>
  );
};
