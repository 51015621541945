import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TranslationsContext } from '../../../context/TranslationsContext';
import './style.css';
import {TransparentButton} from '../../../components/Button/TransparentButton';

const NavItem = ({link, icon, text, className}) => {
  const location = useLocation();
  const { fullTranslations, mainLang } = useContext(TranslationsContext);
  const Icon = icon;
  const isActive = location?.pathname?.includes(link);
  return (
      <Link to={`/${mainLang}/services/${link}`} className={`services-nav-item ${isActive ? 'active' : ''} ${className}`}>
        {icon && <Icon isActive={isActive}/>}
        {text}
      </Link>
  )
}

export const ServicesNav = () => {
  const { fullTranslations, mainLang } = useContext(TranslationsContext);
  const location = useLocation();

    const isConnection = location?.pathname?.includes('connection-with-passed-away');
    const isTimeCapsule = location?.pathname?.includes('time-capsule');
    const showConnectionButton = !isTimeCapsule;


    return (
    <nav className="services-nav">
     <NavItem
      text={fullTranslations?.services?.timeCapsule}
      link={'time-capsule'}
      icon={TimeCapsuleIcon}
     />
      <NavItem
          text={fullTranslations?.services?.digitalAvatar}
          link={'digital-avatar'}
          icon={DigitalAvatarIcon}
      />
        {(showConnectionButton) && <NavItem
            className="services-nav-item-connection"
            text={fullTranslations?.services?.connection}
            link={'connection-with-passed-away'}
        />}
      {(showConnectionButton) && <Link
          to={`/${mainLang}/services/connection-with-passed-away`}>
        <TransparentButton  className={`services-connection-button ${isConnection && 'button-transparent-active'}`}>
          {fullTranslations?.services?.connection}
        </TransparentButton>
      </Link>}
    </nav>
  );
};

const TimeCapsuleIcon = ({ isActive }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17285 1.68764C13.1316 1.77955 16.3126 5.01912 16.3126 8.99996C16.3126 13.0421 13.0451 16.3201 8.99899 16.3125C4.94945 16.3048 1.64273 12.977 1.68758 8.92363C1.70589 7.15099 2.36796 5.44544 3.55048 4.12474C3.75771 3.89329 4.11332 3.87366 4.34476 4.08089C4.57621 4.28812 4.59584 4.64373 4.38861 4.87518C3.38811 5.9926 2.82796 7.43564 2.81252 8.93543L2.81252 8.93589C2.77452 12.3602 5.573 15.181 9.00111 15.1875C12.4222 15.1939 15.1876 12.4229 15.1876 8.99996C15.1876 5.77198 12.7156 3.12005 9.56255 2.83586V5.34371C9.56255 5.65437 9.31071 5.90621 9.00005 5.90621C8.68939 5.90621 8.43755 5.65437 8.43755 5.34371V2.40414C8.43742 2.30839 8.4565 2.21358 8.49367 2.12533C8.53097 2.03676 8.58573 1.95661 8.65467 1.88966C8.72361 1.82272 8.80533 1.77033 8.89495 1.73564C8.9835 1.70137 9.07797 1.68506 9.17285 1.68764Z"
      fill="white"
      fillOpacity={isActive ? '1' : '0.5'}
    />
    <path
      d="M8.20482 9.79554L5.42747 5.82289C5.38905 5.76785 5.37123 5.70104 5.37713 5.63417C5.38302 5.5673 5.41227 5.50464 5.45973 5.45718C5.5072 5.40971 5.56986 5.38047 5.63672 5.37457C5.70359 5.36867 5.7704 5.3865 5.82544 5.42492L9.7981 8.20226C10.0418 8.37776 10.2066 8.64213 10.2568 8.93818C10.3071 9.23424 10.2388 9.53818 10.0667 9.78426C9.89454 10.0303 9.63248 10.1988 9.33715 10.2531C9.04182 10.3075 8.73696 10.2434 8.48853 10.0747C8.37868 9.99856 8.28271 9.90414 8.20482 9.79554Z"
      fill="white"
      fillOpacity={isActive ? '1' : '0.5'}
    />
  </svg>
);
const DigitalAvatarIcon = ({ isActive }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 3.9375C10.0873 3.9375 10.9688 3.05606 10.9688 1.96875C10.9688 0.881439 10.0873 0 9 0C7.91269 0 7.03125 0.881439 7.03125 1.96875C7.03125 3.05606 7.91269 3.9375 9 3.9375Z"
      fill="white"
      fillOpacity={isActive ? '1' : '0.5'}
    />
    <path
      d="M16.3125 4.5H1.6875V6.32812H6.75L5.625 17.7585L7.41797 18L8.1791 11.25H9.83215L10.582 18L12.375 17.7546L11.25 6.32812H16.3125V4.5Z"
      fill="white"
      fillOpacity={isActive ? '1' : '0.5'}
    />
  </svg>
);
