import {createContext, useEffect, useState} from 'react';

export const languages = [
  { value: 'ru', description: 'Русский' },
  { value: 'en', description: 'English' },
];
export const fullLanguages = [
  { value: 'ru', description: 'Русский' },
  { value: 'en', description: 'English' },
  { value: 'pt', description: 'Português' },
  { value: 'es', description: 'Español' },
];

export const browserLanguage = navigator.language.split(/[-_]/)[0];
export const defaultLanguage = 'en';

const getLang = (paramLang, langsArray, localStorageName, setLangState) => {
  const includeBrowserLanguage = langsArray.find((lang) =>
    browserLanguage.toLowerCase().includes(lang.value.toLowerCase())
  );

  const includeParamLanguage = langsArray.find((lang) =>
    paramLang?.toLowerCase().includes(lang.value.toLowerCase())
  );
  // console.log(includeParamLanguage);
    const savedLang = sessionStorage.getItem(localStorageName);

    setLangState(
    includeParamLanguage
      ? includeParamLanguage.value
      : savedLang
      ? savedLang
            : includeBrowserLanguage
      ? includeBrowserLanguage.value
      : defaultLanguage
  );
};

const getLanguageDictionary = (lang, setTranslations, isFull) => {
  if (lang) {
    import(`../locales/${isFull ? 'full_' : ''}${lang}.js`).then((data) => {
      setTranslations(data.lang);
    });
  } else {
    import(`../locales/${isFull ? 'full_' : ''}${defaultLanguage}.js`).then(
      (data) => {
        setTranslations(data.lang);
      }
    );
  }
};

export const TranslationsContext = createContext();

export function TranslationsProvider({ children }) {
  const [profileLang, setProfileLang] = useState(defaultLanguage);
  const [profileTranslations, setProfileTranslations] = useState({});

  const [mainLang, setMainLang] = useState(defaultLanguage);
  const [fullTranslations, setFullTranslations] = useState({});

  const [paramLang, setParamLang] = useState('');

  // useEffect(() => {
  //   console.log(mainLang);
  // }, [mainLang]);
  // console.log(mainLang);
  useEffect(() => {
      if (paramLang) {
          getLang( paramLang || '', languages, 'mykinProfileLang', setProfileLang);
          getLang( paramLang, fullLanguages, 'mykinMainLang', setMainLang);
      }

    // if (
    //   window.location.href.includes('profile') ||
    //   window.location.href.includes('payments-history')
    // ) {
    //   getLang(languages, 'mykinProfileLang', setProfileLang);
    // } else {
    //   getLang(fullLanguages, 'mykinMainLang', setMainLang);
    // }
  }, [paramLang]);

    useEffect(() => {
        getLanguageDictionary(profileLang, setProfileTranslations, false);
    }, [profileLang]);

  useEffect(() => {
    getLanguageDictionary(mainLang, setFullTranslations, true);
    // if (
    //   window.location.href.includes('profile') ||
    //   window.location.href.includes('payments-history')
    // ) {
    //   getLanguageDictionary(profileLang, setProfileTranslations, false);
    // } else {
    //   getLanguageDictionary(mainLang, setFullTranslations, true);
    // }
  }, [mainLang]);

  return (
    <TranslationsContext.Provider
      value={{
        profileLang,
        setProfileLang,
        profileTranslations,
        setProfileTranslations,
        mainLang,
        setMainLang,
        fullTranslations,
        setParamLang,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
}
