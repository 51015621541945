import { useContext, useEffect } from 'react';
import { MainFooter } from '../MainFooter/MainFooter';
import { MainHeader } from '../MainHeader/MainHeader';
import {
  TranslationsContext,
  browserLanguage,
  defaultLanguage,
  fullLanguages,
} from '../../context/TranslationsContext';
import { useLocation, useNavigate, useParams } from 'react-router';

export const updatedPathname = (location) => {
  let updatedPathname = '';
  if (
    location.pathname.includes(':lang') ||
    location.pathname.includes('/en') ||
    location.pathname.includes('/pt') ||
    location.pathname.includes('/es') ||
    location.pathname.includes('/ru')
  ) {
    updatedPathname = location.pathname.split('/').splice(2).join('/');
  }
  // console.log(updatedPathname);
  return updatedPathname;
};

export const MainLayout = ({ children }) => {
  const { lang } = useParams();
  const location = useLocation();

  const { mainLang, setParamLang } = useContext(TranslationsContext);
  const navigate = useNavigate();

  useEffect(() => {
    setParamLang(lang);
    if (!lang || lang === ':lang') {
      const savedLang = sessionStorage.getItem('mykinMainLang');
      if (savedLang) {
        setParamLang(savedLang);
        navigate(
          `/${savedLang}${
            updatedPathname(location) ? `/${updatedPathname(location)}` : ''
          }`
        );
      } else {
        const includeBrowserLanguage = fullLanguages.find((lang) =>
          browserLanguage.toLowerCase().includes(lang.value.toLowerCase())
        );
        if (includeBrowserLanguage.value) {
          setParamLang(includeBrowserLanguage.value);
          sessionStorage.setItem('mykinMainLang', includeBrowserLanguage.value);
          navigate(
            `/${includeBrowserLanguage.value}${
              updatedPathname(location) ? `/${updatedPathname(location)}` : ''
            }`
          );
        } else {
          setParamLang('ru');
          sessionStorage.setItem('mykinMainLang', 'ru');
        }
      }
    }
  }, [lang]);

  useEffect(() => {
    if (lang && lang !== ':lang' && !fullLanguages.find((language) => language.value === lang)) {

      const includeBrowserLanguage = fullLanguages.find((language) =>
        browserLanguage.toLowerCase().includes(language.value.toLowerCase())
      );
      const langToUpdate = includeBrowserLanguage?.value
        ? includeBrowserLanguage.value
        : defaultLanguage;
      localStorage.setItem('mykinMainLang', langToUpdate);
      navigate(
        `/${langToUpdate}${
          updatedPathname(location) ? `/${updatedPathname(location)}` : ''
        }`
      );
    }
  }, [lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // navigate(`/${mainLang}`);
  }, [mainLang]);

  return (
    <>
      <MainHeader />
      {children}
      <MainFooter />
    </>
  );
};
