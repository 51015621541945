export const ArrowPlay = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.90894 19.4062L16.5552 12.854C16.6784 12.7484 16.7773 12.6174 16.845 12.47C16.9128 12.3226 16.9479 12.1622 16.9479 12C16.9479 11.8377 16.9128 11.6774 16.845 11.5299C16.7773 11.3825 16.6784 11.2515 16.5552 11.1459L8.90894 4.59371C8.1791 3.96839 7.05176 4.48683 7.05176 5.44777V18.554C7.05176 19.515 8.1791 20.0334 8.90894 19.4062Z"
      fill="#4767C7"
    />
  </svg>
);
