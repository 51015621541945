import { useContext } from 'react';
import { MainLayout } from '../../components/Layouts/MainLayout';
import { TranslationsContext } from '../../context/TranslationsContext';
import './style.css';
import { PrivacyPolicyHelmet } from './components/PrivacyPolicyHelmet';

export const PrivacyPolicy = () => {
  const { fullTranslations } = useContext(TranslationsContext);

  return (
    <>
      <PrivacyPolicyHelmet />
      <MainLayout>
        <main className="container pp-container">
          <h1>{fullTranslations?.privacyPolicy?.title}</h1>
          {fullTranslations?.privacyPolicy?.items?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </main>
      </MainLayout>
    </>
  );
};
