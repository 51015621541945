import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import Login from './pages/Login/Login';
import Tree from './pages/Tree/Tree';
import {NoMatch} from './pages/NoMatch/NoMatch';
import {ProtectedRoute} from './components/ProtectedRoute';
import {useContext} from 'react';
import {Profile} from './pages/Profile/Profile';
import {PaymentsHistory} from './pages/PaymentsHistory/PaymentsHistory';
import {OnlyUnAuthRoute} from './components/OnlyUnAuthRoute';
import {AuthContext} from './context/AuthContext';
import {Main} from './pages/Main/Main';
import {PrivacyPolicy} from './pages/PrivacyPolicy/PrivacyPolicy';
import {TermsOfUse} from './pages/TermsOfUse/TermsOfUse';
import {ServicesTimeCapsule} from './pages/Services/ServicesTimeCapsule';
import {ServicesDigitalAvatar} from './pages/Services/ServicesDigitalAvatar';
import {ForgotPasswordPage} from './pages/Login/ForgotPasswordPage/ForgotPasswordPage';
import ServicesConnection from './pages/Services/Connection/ServicesConnection';
import ShortStory from './pages/ShortStory';
import Intro from './pages/Intro';

function App() {
  const {isAuthState} = useContext(AuthContext);

  return (
    <Routes>
      <Route path={'/'} element={<Main/>}/>
      <Route path={'/:lang'} element={<Main/>}/>

      <Route
        path={'/services'}
        element={<Navigate to={'/services/time-capsule'} replace/>}
      />
      <Route
        path={'/:lang/services'}
        element={<Navigate to={'/:lang/services/time-capsule'} replace/>}
      />

      <Route
        path={'/services/time-capsule'}
        element={<ServicesTimeCapsule/>}
      />
      <Route
        path={'/:lang/services/time-capsule'}
        element={<ServicesTimeCapsule/>}
      />

      <Route
        path={'/services/digital-avatar'}
        element={<ServicesDigitalAvatar/>}
      />
      <Route
        path={'/:lang/services/digital-avatar'}
        element={<ServicesDigitalAvatar/>}
      />
      <Route
        path={'/services/connection-with-passed-away'}
        element={<ServicesConnection/>}
      />
      <Route
        path={'/:lang/services/connection-with-passed-away'}
        element={<ServicesConnection/>}
      />
      <Route
        path={'/story-about-grandpa'}
        element={<ShortStory/>}
      />
      <Route
        path={'/:lang/story-about-grandpa'}
        element={<ShortStory/>}
      />

      <Route
        path={'/intro'}
        element={<Intro/>}
      />
      <Route
        path={'/:lang/intro'}
        element={<Intro/>}
      />

      <Route path={'/pp'} element={<PrivacyPolicy/>}/>
      <Route path={'/:lang/pp'} element={<PrivacyPolicy/>}/>

      <Route path={'/ua'} element={<TermsOfUse/>}/>
      <Route path={'/:lang/ua'} element={<TermsOfUse/>}/>

      <Route path={'/tree'} element={<Tree/>}/>
      <Route path={'/:lang/tree'} element={<Tree/>}/>

      <Route path={`/tree/:userId/:treeId`} element={<Tree/>}/>
      <Route path={`/:lang/tree/:userId/:treeId`} element={<Tree/>}/>

      <Route
        path={'/login'}
        element={
          <OnlyUnAuthRoute auth={isAuthState}>
            <Login/>
          </OnlyUnAuthRoute>
        }
      />
      <Route
        path={'/:lang/login'}
        element={
          <OnlyUnAuthRoute auth={isAuthState}>
            <Login/>
          </OnlyUnAuthRoute>
        }
      />

      <Route
        path={'/restore-password'}
        element={
          <OnlyUnAuthRoute auth={isAuthState}>
            <ForgotPasswordPage/>
          </OnlyUnAuthRoute>
        }
      />
      <Route
        path={'/:lang/restore-password'}
        element={
          <OnlyUnAuthRoute auth={isAuthState}>
            <ForgotPasswordPage/>
          </OnlyUnAuthRoute>
        }
      />

      <Route
        path={'/profile'}
        element={
          <ProtectedRoute auth={isAuthState}>
            <Profile/>
          </ProtectedRoute>
        }
      />

      <Route
        path={'/payments-history'}
        element={
          <ProtectedRoute auth={isAuthState}>
            <PaymentsHistory/>
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NoMatch/>}/>
    </Routes>
  );
}

export default App;
