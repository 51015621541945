import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../configs/firebase';

const apiTree = process.env.REACT_APP_API_TREE;
const apiPayments = process.env.REACT_APP_API_PAYMENTS;
const usersCollection = 'users';

export const getTreeData = async (userId, treeId) => {
  const data = await axios(`${apiTree}/tree/${userId}/${treeId}`);
  return data?.data;
};

export const postPayment = async (data, lang) => {
  return await axios.post(`${apiPayments}/api/v1/payments/`, data, {
    headers: {
      'Accept-Language': lang,
    },
  });
};

export const sendOrderToEmail = async (data, lang) => {
  return await axios.post(`${apiPayments}/api/v1/time_capsule_request/`, data, {
    headers: {
      'Accept-Language': lang,
    },
  });
};

export const sendOrderToTelegram = async (data, lang) => {
  return await axios.post(`https://mykin.in/api/order/`, data, {
    headers: {
      'Accept-Language': lang,
    },
  });
};

export const getPaymentsHistory = async (user_id) => {
  const data = await axios(`${apiPayments}/api/v1/status/${user_id}/`);
  return data?.data;
};

export const getUserInfo = async (userId) => {
  const ref = doc(firestore, usersCollection, userId);
  const docSnap = await getDoc(ref).then((doc) => doc.data());
  return docSnap;
};
