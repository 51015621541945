import { useContext, useRef, useState } from 'react';
import logo from '../../../assets/images/logo.svg';
import { BurgerMenu } from '../../../components/BurgerMenu/BurgerMenu';
import { useClickOutside } from '@react-hookz/web';
import burger from '../../../assets/images/burger.svg';
import { ChangeLangModal } from '../../../components/ChangeLang/ChangeLangModal';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { ChangeLangMenu } from '../../../components/ChangeLang/ChangeLangMenu';
import { Link } from 'react-router-dom';

export const TreeHeader = ({ title }) => {
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [langModalIsOpened, setLangModalIsOpened] = useState(false);

  const { mainLang, setMainLang } = useContext(TranslationsContext);

  const burgerRef = useRef(null);

  useClickOutside(burgerRef, (e) => {
    if (
      !(
        e.target.id === 'lang' ||
        e.target.id === 'logout' ||
        e.target.id === 'myProfile' ||
        e.target.id === 'services' ||
        e.target.id === 'aboutUs'
      )
    ) {
      setMenuIsOpened(false);
    }
  });

  const openLangModal = () => {
    setLangModalIsOpened(true);
  };

  return (
    <header className="root__header header">
      <div className="container">
        <div className="header__wrap">
          <h1 className="header__title js-title">
            {title?.length > 23 ? `${title?.slice(0, 20)}...` : title}
          </h1>

          <Link to={`/${mainLang}`} className="header__logo">
            <span className="header__logo--image">
              <img src={logo} className="img-fluid" alt="Mykin" />
            </span>
            <span className="header__logo--text">MyKin</span>
          </Link>

          <div className="main-header-right">
            <ChangeLangMenu />
          </div>
          <button
            ref={burgerRef}
            type="button"
            className="burger-button"
            onClick={() => {
              setMenuIsOpened(!menuIsOpened);
            }}
          >
            <img src={burger} alt="open menu" />
          </button>
          {menuIsOpened && <BurgerMenu openLangModal={openLangModal} />}
        </div>
      </div>

      <ChangeLangModal
        langModalIsOpened={langModalIsOpened}
        setLangModalIsOpened={setLangModalIsOpened}
      />
    </header>
  );
};
