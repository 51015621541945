import { PortalModal } from '../../../components/PortalModal/PortalModal';
// import video from '../../../assets/video/mainVideo.mp4';
import { useContext } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';

export const VideoModal = ({ isOpenVideo, setIsOpenVideo }) => {
  const { mainLang } = useContext(TranslationsContext);

  return (
    <PortalModal
      isOpen={isOpenVideo}
      onClose={() => setIsOpenVideo(false)}
      className={'modal-c-backdrop'}
      childrenClassName={'js-modal-dialog'}
    >
      {/* <video src={video} width="960" height="544" controls="controls" autoPlay className='main-video'>
        <source src={video} type="video/mp4; codecs='avc1.42E01E, mp4a.40.2'" />
      </video> */}
      {mainLang === 'ru' ? (
        <iframe
          width="1227"
          height="690"
          src="https://www.youtube.com/embed/EqgCiXi06Ak?autoplay=1"
          title="Цифровой аватар предка и клонирование голоса. Мобильное приложение Mykin."
          frameBorder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className='main-video'
        ></iframe>
      ) : (
        <iframe
          width="1227"
          height="690"
          src="https://www.youtube.com/embed/7kFOSB12HyA?autoplay=1"
          title="Ancestor&#39;s digital avatar and voice cloning. Mobile App Mykin."
          frameBorder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className='main-video'

        ></iframe>
      )}
    </PortalModal>
  );
};
