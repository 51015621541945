import {useContext} from 'react';
import {TranslationsContext} from '../../../context/TranslationsContext';
import {MainLayout} from '../../../components/Layouts/MainLayout';
import {ServicesNav} from '../components/ServicesNav';
import './style.css';
import {DownloadLinks} from '../../../components/DownloadLinks/DownloadLinks';
import GrandfatherImg from '../../../assets/images/services/connection/grandfather-old.png';
import GrandfatherImgSmall from '../../../assets/images/services/connection/grandfather-small.png';
import GrandfatherWithGirlImg from '../../../assets/images/services/connection/grandfather-with-girl.png';
import GrandfatherWithGirlImgSmall from '../../../assets/images/services/connection/grandfather-with-girl-small.png';
import {ServiceConnectionHelmet} from './ServiceConnectionHelmet';

const ServicesConnection = () => {
    const {fullTranslations, mainLang} = useContext(TranslationsContext);

    return (
        <>
            <ServiceConnectionHelmet/>

            <MainLayout>
                <main className="services-container">
                    <div className="container">
                        <div className="services-nav-container">
                            <ServicesNav/>
                        </div>
                        <div className={'services-connection-container'}>
                            <h1 className={"services-connection-header"}>
                                {fullTranslations.connection?.title}
                            </h1>
                            <div className={'services-connection-content'}>
                                {fullTranslations.connection?.content}
                            </div>
                            <div className={'services-connection-message-img'}>
                                <img
                                    src={fullTranslations.connection?.messageImg?.big}
                                    srcSet={`${fullTranslations.connection?.messageImg?.small} 400w, ${fullTranslations.connection?.messageImg?.big} 1000w`}
                                    sizes={'(max-width: 450px)'}
                                />
                            </div>
                            <div className={'services-connection-post-scriptum'}>
                                <p>P.S.</p>
                                <p>
                                    {fullTranslations.connection?.postScriptum}
                                </p>
                            </div>
                            <DownloadLinks isBlack/>
                            <div className={'services-connection-app-info'}>
                                <p>
                                    {fullTranslations.connection?.appInfo}
                                </p>
                            </div>
                            <div className={'services-connection-img'}>
                                <img
                                    src={GrandfatherImg}
                                    srcSet={`${GrandfatherImgSmall} 400w, ${GrandfatherImg} 1000w`}
                                    sizes={'(max-width: 450px)'}
                                />
                                <p>
                                    {fullTranslations.connection?.grandfatherImgDescription}
                                </p>
                            </div>
                            <div className={'services-connection-img'}>
                                <img
                                    src={GrandfatherWithGirlImg}
                                    srcSet={`${GrandfatherWithGirlImgSmall} 400w, ${GrandfatherWithGirlImg} 1000w`}
                                    sizes={'(max-width: 450px)'}
                                />
                                <p>
                                    {fullTranslations.connection?.grandfatherWithGirlImgDescription}
                                </p>
                            </div>
                        </div>


                    </div>
                </main>


            </MainLayout>
        </>
    );
};

export default ServicesConnection;
