import { useContext, useState } from 'react';
import { LoginHelmet } from '../components/LoginHelmet';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { ArrowBack } from '../Login';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo_image.svg';
import { useForm } from 'react-hook-form';
import { passwordReset } from '../../../configs/firebase';

export const ForgotPasswordPage = () => {
  const { mainLang, fullTranslations } = useContext(TranslationsContext);

  const [showUsernameError, setShowUsernameError] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const { register, reset, handleSubmit } = useForm({
    mode: 'onChange ',
  });


  const handleRestorePassword = async (data) => {
    try {
      await passwordReset(data.username);
      setEmailMessage(true);
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setShowUsernameError(true);
        // reset();
      }
    }
  };

  return (
    <>
      <LoginHelmet />
      <main className={'auth'}>
        <Link to={`/${mainLang}/login`} className="auth-to-main">
          <ArrowBack />
          {fullTranslations?.login?.goBack}
        </Link>
        <div className={'auth-form'}>
          <div className={'auth-form-container'}>
            <img
              src={logo}
              alt="logo"
              width="105"
              height="102"
              className="auth-logo"
            />
            <h1>{fullTranslations?.restorePassword?.title}</h1>
            {emailMessage ? (
              <div>
                <div>{fullTranslations?.restorePassword?.linkSend}</div>
                <Link to={`/${mainLang}/login`} className={'auth-link-restore'}>{fullTranslations?.restorePassword?.retryLogin}</Link>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit(handleRestorePassword)}
                className={'auth-login-form'}
              >
                <label>
                  {fullTranslations?.login?.email}
                  <input
                    type="text"
                    placeholder={fullTranslations?.restorePassword?.placeholder}
                    {...register('username', {
                      required: true,
                    })}
                    className={`auth-input`}
                    onChange={() => {
                      setShowUsernameError(false);
                    }}
                  />
                </label>
                {showUsernameError && (
                  <p className={'auth-input_error-message'} style={{alignSelf: 'flex-start'}}>
                    * {fullTranslations?.restorePassword?.errorEmail}
                  </p>
                )}
                <button className={'login-button-password'}>
                  {fullTranslations?.restorePassword?.button}
                </button>
              </form>
            )}
          </div>
        </div>
        <div className={'auth-img'}></div>
      </main>
    </>
  );
};
