import './style.css';
import { SliderMain } from './components/SliderMain';
import { useContext, useMemo, useState } from 'react';
import { VideoModal } from './components/VideoModal';
import { MainLayout } from '../../components/Layouts/MainLayout';
import { DownloadBlock } from './components/DownloadBlock';
import { InstructionsBlock } from './components/InstructionBlock/InstructionsBlock';
import { TranslationsContext } from '../../context/TranslationsContext';
import { ArrowPlay } from '../../assets/images/main/ArrowPlay';
import { MainHelmet } from './components/MainHelmet';
import VideoBlock from '../../components/VideoBlock/VideoBlock';

export const Main = () => {
  const { mainLang, fullTranslations } = useContext(TranslationsContext);

  const [isOpenVideo, setIsOpenVideo] = useState(false);

  const splitTitle = useMemo(
    () =>
      fullTranslations?.main?.title?.split(' ')?.map((word) => (
        <span className="title-word-wrapper" key={word}>
          <span className="title-word">{word}</span>
        </span>
      )),
    [fullTranslations]
  );

  return (
    <>
      <MainHelmet />
      <MainLayout>
        <main className="main-page">
          <div className="bg-container">
            <span className="bg-container_back"></span>
            <span className="bg-container_left"></span>
            <span className="bg-container_top"></span>
            <div className="bubble_container">
              <section className="container">
                <h1 key={mainLang}>{splitTitle}</h1>

                <SliderMain />

                <button onClick={() => setIsOpenVideo(true)}>
                  {fullTranslations?.main?.watchVideo}
                  <ArrowPlay />
                </button>

                {/* <div className="cookie">
            На этом сайте используются файлы cookie для улучшения работы с
            сайтом
          </div> */}
                {/* <div id="myblob_big">
            <span></span>
            <span></span>
          </div>
          <div id="myblob_small">
            <span></span>
            <span></span>
          </div> */}
              </section>
            </div>
          </div>
          <div className="main-page-bottom container">
            <DownloadBlock />
            <VideoBlock/>
            <InstructionsBlock />
          </div>
        </main>

        <VideoModal isOpenVideo={isOpenVideo} setIsOpenVideo={setIsOpenVideo} />
      </MainLayout>
    </>
  );
};
