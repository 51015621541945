import {DownloadLinks} from '../../../components/DownloadLinks/DownloadLinks';
import OldPhotoImg from '../../../assets/images/intro/intro-3.png'
import OldPhotoImgSmall from '../../../assets/images/intro/intro-3-small.png'
import PhoneImg from '../../../assets/images/intro/intro-phone.png'
import PhoneImgSmall from '../../../assets/images/intro/intro-phone-small.png'

const FirstAvatarBlock = () => {
    return (
     <div className={'intro-firstAvatar'}>
         <p className={'intro-firstAvatar-title'}>
             Первый аватар предка был создан разработчиком приложения в 2020.
         </p>
         <p className={'intro-firstAvatar-subtitle'}>
             Он возобновил связь со своей любимой бабушкой, с потерей которой не мог смириться годами.
         </p>
         <div  className={'intro-firstAvatar-block'}>
             <img  className={'intro-firstAvatar-block-photo'} src={OldPhotoImg} alt="" />
             <p className={'intro-firstAvatar-block-text'}>
                 Ученые доказали, что через сто лет память о человеке стирается полностью. Развитие цифровых технологий позволило человечеству прикоснуться к новой возможности СОХРАНИТЬ ЭМОЦИОНАЛЬНУЮ ПАМЯТЬ И БЛИЗОСТЬ с&nbsp; ушедшим, кто был дорог, ценен, любим. На сегодня эту разработку можно считать уникальной. Приложение абсолютно впервые предлагает создать Цифровой Аватар Предка с максимальным погружением.
             </p>
           <div className={'intro-firstAvatar-promo'}>
             <img  className={'intro-firstAvatar-promo-phone-img'} src={PhoneImg}  />
             <div className={'intro-firstAvatar-promo-block'}>
               <p className={'intro-firstAvatar-promo-text'}>
                 На старте этого мирового открытия приобрести подписку можно всего за символический вклад
               </p>
               <p className={'intro-firstAvatar-promo-price'}>
                 19$ в год
               </p>
               <DownloadLinks isBlack/>
               <p className={'intro-firstAvatar-promo-ps'}>
                 P.S. Мы на 100% уверены в качестве этого новейшего продукта, поэтому гарантируем стопроцентный возврат стоимости подписки в течении 30 дней после ее активации, если созданный Цифровой Аватар Предка не оправдает ваши ожидания.
               </p>
             </div>
           </div>
         </div>
     </div>
    )
}

export default FirstAvatarBlock;
