import { Helmet } from 'react-helmet-async';
import { useContext } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';

export const ServiceConnectionHelmet = () => {
  const { fullTranslations, mainLang } = useContext(TranslationsContext);

  return (
    <Helmet htmlAttributes={{ lang: mainLang, amp: undefined }}>
      <title>{fullTranslations?.head?.connection?.title}</title>
      <meta
        name="description"
        content={fullTranslations?.head?.connection?.description}
      />
      <meta
        property="og:url"
        content="https://mykin.in/connection-with-passed-away"
      />
      <meta
        property="og:image"
        content="https://www.mykin.in/timeCapsulePreview.png"
      />
      <meta property="og:image:width" content="1032" />
      <meta property="og:image:height" content="997" />
      <meta
        property="og:title"
        content={fullTranslations?.head?.connection?.title}
      />
      <meta
        property="og:description"
        content={fullTranslations?.head?.connection?.ogDescription}
      />
      <meta
        name="twitter:image"
        content="https://www.mykin.in/timeCapsulePreview.png"
      />
      <meta
        name="twitter:title"
        content={fullTranslations?.head?.connection?.title}
      />
      <meta
        name="twitter:description"
        content={fullTranslations?.head?.connection?.ogDescription}
      />
      <meta
        property="vk:image"
        content="https://www.mykin.in/timeCapsulePreview.png"
      />
      <meta
        property="vk:url"
        content="https://mykin.in/connection-with-passed-away"
      />
      <meta
        property="vk:title"
        content={fullTranslations?.head?.connection?.title}
      />
      <meta
        property="vk:description"
        content={fullTranslations?.head?.connection?.ogDescription}
      />
    </Helmet>
  );
};
