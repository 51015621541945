import React from 'react';
import {createPortal} from 'react-dom';
import {useClickOutside, useKeyboardEvent} from '@react-hookz/web';

export const PortalModal = (
	{
		isOpen,
		onClose,
		children,
		childrenClassName,
		excludeOnCloseClassNames,
		className = ''
	}) => {
	useKeyboardEvent('Escape', () => isOpen && onClose());
	// const ref = useRef()
	// useClickOutside(isOpen && onClose, excludeOnCloseClassNames);

	if (!isOpen) return null
	
	function onCloseHandler(ev) {
		ev.stopPropagation()
		
		if (!onClose) return
		
		if (childrenClassName && !ev.target.closest(`.${childrenClassName}`))
			onClose()

		if (excludeOnCloseClassNames && !excludeOnCloseClassNames?.some(_className => ev.target.closest(`.${_className}`)))
			onClose()
	}
	
	return createPortal(
		<div className={`position-fixed ${className}`}  onClick={onCloseHandler}>
			{children}
		</div>,
		document.body
	)
  }