import {TreeAdapter, TreeViewIndents} from "./TreeAdapter";
import femaleSVG from '../assets/images/female.svg';
import maleSVG from '../assets/images/male.svg';
import neutralSVG from '../assets/images/neutral.svg';

export const defaultImg = {
  neutral: neutralSVG,
  female: femaleSVG,
  male: maleSVG
 }

export function drawTree(personArray, openModal) {
	const canvas = document.getElementById("canvas")
	const ctx = canvas.getContext('2d')
	
	let adapter = new TreeAdapter(personArray)
	let heightRelative = adapter.getRelativeHeight()
	let widthRelative = adapter.maxRelative + Math.abs(adapter.minRelative) + 1
	
	const scaleFactor = 1
	
	const nodeSize = 80 * scaleFactor
	const levelSeparation = 100 * scaleFactor
	const halfNodeSize = nodeSize / 2
	
	
	let height = (adapter.getRelativeHeight() - 1) * levelSeparation
		+ (adapter.getRelativeHeight() + 2) * (nodeSize)
	
	let relativeWidth = adapter.maxRelative + Math.abs(adapter.minRelative)
	
	let width = (relativeWidth) * TreeViewIndents.DEFAULT_SIBLING_SEPARATION
		+ (relativeWidth + 2) * (nodeSize)
	
	ctx.canvas.width = width * 2
	// document.body.style.minWidth = width + 'px'
	
	ctx.canvas.height = height * 2
	// document.body.style.minHeight = height + 'px'
	
	ctx.lineWidth = 2 * scaleFactor
	ctx.strokeStyle = '#fff'
	
	
	let scaleDownCanvas = document.documentElement.clientWidth / width

	scaleDownCanvas = Math.min(scaleDownCanvas, 1)
	
	ctx.scale(2, 2);

  const canvasTransformerElement = document.querySelector('.canvas');

  canvasTransformerElement.style.transform = `translateY(-${25 + (1 - scaleDownCanvas) * 20}%) scale(${scaleDownCanvas / 2}, ${scaleDownCanvas / 2})`

  const canvasContainer =  document.querySelector('.canvas-container')
	canvasContainer.style.height = `${scaleDownCanvas * height}px`
	
  const rootMain = document.querySelector('.root__main')
  const headerTitle = document.querySelector('.header__title')

  rootMain.classList.add('fade-in')
  headerTitle.classList.add('fade-in')

	drawNodes()
	drawLine()
	
	
	function drawNodes() {
		for (let index = 0; index < adapter.nodeList.length; index++) {
			
			let node = adapter.getNode(index);

			let xRelation =
				(node.relative + 0.5 + Math.abs(adapter.minRelative)) / (widthRelative + 0.7);
			
			let left = (xRelation * width);

			let top =
				((heightRelative -
						(1.5 + node.level + Math.abs(adapter.getMinLevel()))) *
					nodeSize +
					(heightRelative - 1 -
						(node.level + Math.abs(adapter.getMinLevel() + 1)))
					* levelSeparation);
			
			const circle = new Path2D();
			
			ctx.beginPath();
			circle.arc(left + halfNodeSize, top + halfNodeSize, halfNodeSize, 0, 2 * Math.PI);
			
			ctx.strokeStyle = '#ffffff'
			ctx.fillStyle = "rgba(87,93,100,0.5)"
			
			if (index === 0) {
				ctx.strokeStyle = '#799BFF'
			}
			
			ctx.fill(circle);
			ctx.stroke(circle);
			
			let mouseover, mouseout;
			
			canvas.addEventListener("mousemove", function (event) {
				mouseout = mouseover;
				mouseover = ctx.isPointInPath(circle, event.offsetX, event.offsetY)
				
				if (mouseover !== mouseout) {
					if (mouseout) {
						document.body.style.cursor = "default";
						//Событие mouseout у фигуры mouseout
					}
					if (mouseover) {
						document.body.style.cursor = "pointer";
						//Событие mouseover у фигуры mouseover
					}
				}
			});
			
			canvas.addEventListener('click', function () {
				if (mouseover) {
					openModal(node.person)
				}
			})
			
			const image = new Image();
			
			image.src = node.person.photoPath ? node.person.photoPath : defaultImg[node.person.gender];

			node.xLeft = left
			node.yTop = top
			
			image.onload = function () {
				ctx.save();
				ctx.beginPath();
				ctx.arc(left + halfNodeSize, top + halfNodeSize, halfNodeSize - 1, 0, 2 * Math.PI, true);
				ctx.closePath();
				ctx.clip();
				
				const scaleFactor = Math.max(nodeSize / this.width, nodeSize / this.height);
				
				const aspectType =
					this.width / this.height > 1
						? 'horizontal'
						: 'vertical'
        const aspectTypeValue = this.width / this.height
				if (aspectType === 'horizontal') left =
					left - this.width * scaleFactor / 4

				
				if (aspectType === 'vertical') top =
					top - this.height * scaleFactor / 4
				
				ctx.drawImage(
					image,
					node.person.photoPath ? left : left+20,
					(aspectTypeValue > 0.85 && aspectTypeValue < 1) || aspectTypeValue === 1 ? top+20 : top,
					this.width * scaleFactor,
					this.height * scaleFactor);
        //   console.log(aspectType)
				// console.log(image.src === 'https://firebasestorage.googleapis.com/v0/b/mykin-81771.appspot.com/o/TreeImages%2F7be4da22-6c6e-4ead-a5c1-352c9f48da07?alt=media&token=267ae6e9-5fd3-4a32-9b77-b857cd9d01e6' ? `!!!!${image}` : image )
        // console.log(this.width / this.height)
        // console.log(left)
        // console.log(top)
        // console.log(this.width)
        // console.log(this.width * scaleFactor)
        // console.log(this.height)
        // console.log(this.height * scaleFactor)
				ctx.beginPath();
				
				ctx.arc(
					left + halfNodeSize,
					top + halfNodeSize,
					halfNodeSize,
					0,
					2 * Math.PI,
					true);
				
				ctx.clip();
				ctx.closePath();
				ctx.restore();
			}
		}
	}
	
	function drawLine() {
		for (let index = 0; index < adapter.nodeList.length; index++) {
			let node = adapter.getNode(index);
			
			let spouse = adapter.nodeList.find(element => element.person.personId === node.person.spouseId)
			if (spouse != null) {
				// line between spouses
				if (spouse.person.parentId == null || node.person.parentId == null) {
					let xStart
					let xEnd
					if (node.relative > spouse.relative) {
						xStart = spouse.xLeft + nodeSize
						xEnd = node.xLeft
					} else {
						xStart = node.xLeft + nodeSize
						xEnd = spouse.xLeft
					}
					ctx.moveTo(xStart + scaleFactor, node.yTop + halfNodeSize);
					ctx.lineTo(xEnd - scaleFactor, spouse.yTop + halfNodeSize);
					ctx.stroke();
				}
				
				if (node.person.descendantIdsList.length !== 0) {
					ctx.moveTo(
						(node.xLeft + halfNodeSize),
						(node.yTop - scaleFactor)
					)
					ctx.lineTo(
						(node.xLeft + halfNodeSize),
						(node.yTop - levelSeparation / 2) + 20
					)
					
					if (node.left || node.person.personId === adapter.nodeList[0].person.parentId) {
						ctx.quadraticCurveTo(
							(node.xLeft + halfNodeSize),
							(node.yTop - levelSeparation / 2),
							(node.xLeft + halfNodeSize) - 20,
							(node.yTop - levelSeparation / 2)
						)
					} else {
						ctx.quadraticCurveTo(
							(node.xLeft + halfNodeSize),
							(node.yTop - levelSeparation / 2),
							(node.xLeft + halfNodeSize) + 20,
							(node.yTop - levelSeparation / 2)
						)
					}
					
					if (node.left || node.person.personId === adapter.nodeList[0].person.parentId) {
						ctx.lineTo(
							(spouse.xLeft + halfNodeSize + 20),
							(spouse.yTop - levelSeparation / 2)
						)
						ctx.quadraticCurveTo(
							(spouse.xLeft + halfNodeSize),
							(spouse.yTop - levelSeparation / 2),
							(spouse.xLeft + halfNodeSize),
							(spouse.yTop - levelSeparation / 2) + 20
						)
					} else {
						ctx.lineTo(
							(spouse.xLeft + halfNodeSize - 20),
							(spouse.yTop - levelSeparation / 2)
						)
						ctx.quadraticCurveTo(
							(spouse.xLeft + halfNodeSize),
							(spouse.yTop - levelSeparation / 2),
							(spouse.xLeft + halfNodeSize),
							(spouse.yTop - levelSeparation / 2) + 20
						)
					}
					
					ctx.lineTo((spouse.xLeft + halfNodeSize), (spouse.yTop - scaleFactor)
					)
					ctx.stroke()
				}
			}
			
			if (node.person.parentId != null) {
				
				let thisLevelSeparation = levelSeparation
				
				thisLevelSeparation /= 2
				
				ctx.moveTo((node.xLeft + halfNodeSize),
					(node.yTop + nodeSize + scaleFactor)
				)
				
				ctx.lineTo((node.xLeft + halfNodeSize),
					(node.yTop + nodeSize + thisLevelSeparation - scaleFactor)
				)
				
				ctx.stroke()
			}
		}
	}
}
