import { useContext } from "react";
import { TranslationsContext } from "../../../context/TranslationsContext";
import { Helmet } from "react-helmet-async";

export const HistoryHelmet = () => {
  const { profileTranslations, profileLang } = useContext(TranslationsContext);

  return (
    <Helmet htmlAttributes={{ lang: profileLang, amp: undefined }}>
    <title>{profileTranslations?.head?.historyPage?.title}</title>
    <meta
      name="description"
      content={profileTranslations?.head?.historyPage?.description}
    />

    <meta property="og:url" content="https://www.mykin.in/" />
    <meta property="og:image" content="https://www.mykin.in/preview.png" />
    <meta property="og:image:width" content="663" />
    <meta property="og:image:height" content="649" />
    <meta
      property="og:title"
      content={profileTranslations?.head?.historyPage?.title}
    />
    <meta
      property="og:description"
      content={profileTranslations?.head?.historyPage?.ogDescription}
    />
    <meta name="twitter:image" content="https://www.mykin.in/preview.png" />
    <meta
      name="twitter:title"
      content={profileTranslations?.head?.historyPage?.title}
    />
    <meta
      name="twitter:description"
      content={profileTranslations?.head?.historyPage?.ogDescription}
    />
    <meta property="vk:url" content="https://www.mykin.in" />

    <meta property="vk:image" content="https://www.mykin.in/preview.png" />
    <meta
      property="vk:title"
      content={profileTranslations?.head?.historyPage?.title}
    />
    <meta
      property="vk:description"
      content={profileTranslations?.head?.historyPage?.ogDescription}
    />
  </Helmet>
  )
}