import { useContext } from 'react';
import { MainLayout } from '../../components/Layouts/MainLayout';
import { TranslationsContext } from '../../context/TranslationsContext';
import './style.css';
import { TermsOfUseHelmet } from './components/TermsOfUseHelmet';

export const TermsOfUse = () => {
  const { fullTranslations } = useContext(TranslationsContext);

  return (
    <>
      <TermsOfUseHelmet />
      <MainLayout>
        <main className="container ua-container">
          <h1>{fullTranslations?.termsOfUse?.title}</h1>
          {fullTranslations?.termsOfUse?.items?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </main>
      </MainLayout>
    </>
  );
};
