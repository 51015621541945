import { useContext } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { DownloadLinks } from '../../../components/DownloadLinks/DownloadLinks';

export const DownloadBlock = () => {
  const { fullTranslations } = useContext(TranslationsContext);

  return (
    <section className="download-block">
      <div className="download-block-content">
        <h2>Mykin</h2>
        <div className="download-block-content-description">
          {fullTranslations?.main?.downloadDescription}
        </div>
        <DownloadLinks isBlack={false}/>
      </div>
    </section>
  );
};
