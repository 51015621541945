// import appStore from '../../assets/images/main/appStore.svg';
// import googlePlay from '../../assets/images/main/googlePlay.svg';
import './style.css';
import { AppStoreIcon, GooglePlayIcon } from './DownloadIcons';

export const DownloadLinks = ({ isBlack }) => {
  return (
    <div className="download-block-content-links">
      <a
        href="https://apps.apple.com/ru/app/mykin/id1595058305"
        target="_blank"
        rel="noreferrer"
      >
        <AppStoreIcon isBlack={isBlack} />
        {/* <img src={isGray ? appStore : ''} alt="app store link" /> */}
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=planx.mykin"
        target="_blank"
        rel="noreferrer"
      >
        <GooglePlayIcon isBlack={isBlack} />
        {/* <img src={googlePlay} alt="google play link" /> */}
      </a>
    </div>
  );
};
