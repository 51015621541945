import './style.css';

export const Table = ({ config, data, className = '' }) => {
  return (
    <>
      <div className="table-top"></div>
      <table className={`table ${className}`}>
        <thead>
          <tr>
            {config.map(({ id, title, props = {} }) => (
              <th {...props} key={id}>
                {title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data?.map((item) => (
            <tr key={item.payment_id}>
              {config.map((col) => (
                <td key={col.id}>{col.cell(item)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-bottom"></div>
    </>
  );
};
