import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logoText from '../../assets/images/logo_text.svg';
import logoImage from '../../assets/images/logo_image.svg';
import burger from '../../assets/images/burger.svg';
import './style.css';
import { TransparentButton } from '../Button/TransparentButton';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { useContext, useMemo, useRef, useState } from 'react';
import { useClickOutside } from '@react-hookz/web';
import { signOut } from 'firebase/auth';
import { auth } from '../../configs/firebase';
import { AuthContext } from '../../context/AuthContext';
import { ChangeLangModal } from '../ChangeLang/ChangeLangModal';
import { TranslationsContext } from '../../context/TranslationsContext';
import { TransparentLink } from '../Button/TransparentLink';
import { ChangeLangMenu } from '../ChangeLang/ChangeLangMenu';
import { Nav } from './Nav';
import noPhoto from '../../assets/images/no-photo-user.png';

export const MainHeader = () => {
    const location = useLocation();

    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const [langModalIsOpened, setLangModalIsOpened] = useState(false);
    const burgerRef = useRef(null);

    const userPhoto = localStorage.getItem('mykinUser');

    const isProfile =
        location.pathname.includes('/profile') ||
        location.pathname.includes('/payments-history');

    const { isAuthState, logOut } = useContext(AuthContext);
    const { mainLang, profileTranslations, fullTranslations } =
        useContext(TranslationsContext);

    useClickOutside(burgerRef, (e) => {
        if (
            !(
                e.target.id?.includes('lang') ||
                e.target.id === 'logout' ||
                e.target.id === 'myProfile' ||
                e.target.id === 'services' ||
                e.target.id === 'aboutUs'
            )
        ) {
            setMenuIsOpened(false);
        }
    });

    const openLangModal = () => {
        setLangModalIsOpened(true);
    };

    const logOutFromProfile =  () => {
        signOut(auth)
            .then(async () => {
                await logOut();
            })
            .catch((error) => {});
    };

    const userPhotoUrl = useMemo(() => {
        return userPhoto?.includes('undefined') || userPhoto?.includes('null') || !userPhoto ?  noPhoto : userPhoto
    }, [userPhoto])

    return (
        <header className={`main-header ${isProfile ? '' : 'border-mob-hidden'}`}>
            <ChangeLangModal
                langModalIsOpened={langModalIsOpened}
                setLangModalIsOpened={setLangModalIsOpened}
            />
            <div className="container">
                <div className="main-header-mobile-top">
                    <Link to={`/${mainLang}`} className='main-header-logo'>
                        <img src={logoImage} alt="mykin" />
                        <img src={logoText} alt="logo" className="main-header-logo-text" />
                    </Link>

                    {isProfile && window.screen.width <= 740 && (
                        <div className="main-header-nav-mob-profile active">
                            {location.pathname.includes('/profile')
                                ? profileTranslations?.header?.profileShort
                                : profileTranslations?.header?.historyShort}
                        </div>
                    )}
                    <button
                        ref={burgerRef}
                        type="button"
                        className="burger-button"
                        onClick={() => {
                            setMenuIsOpened(!menuIsOpened);
                        }}
                    >
                        <img src={burger} alt="open menu" />
                    </button>
                    {menuIsOpened && (
                        <BurgerMenu
                            openLangModal={openLangModal}
                            logOutFromProfile={logOutFromProfile}
                        />
                    )}
                </div>

                <Nav />

                <div className="main-header-right">
                    <ChangeLangMenu />

                    {isProfile ? (
                        <div className="button-transparent-img ml-15">
                            <img src={userPhotoUrl} alt="user" />
                            <TransparentButton className="ml-15" onClick={logOutFromProfile}>
                                {profileTranslations?.header?.logout}{' '}
                            </TransparentButton>
                        </div>
                    ) : isAuthState ? (
                        <TransparentLink
                            className="ml-15 button-transparent-img"
                            toPath="/profile"
                        >
                            <img src={userPhotoUrl} alt="user" />
                        </TransparentLink>
                    ) : (
                        <TransparentLink
                            toPath={`/${mainLang}/login`}
                            className="ml-15 button-transparent"
                        >
                            {fullTranslations?.header?.login}
                        </TransparentLink>
                    )}
                </div>
            </div>
        </header>
    );
};
