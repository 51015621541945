import './style.css';

export const Loader = ({ isBlue }) => {
  return (
    <div className={`loader ${isBlue ? 'loader_blue' : ''}`}>
      <div className="dot dot-1"></div>
      <div className="dot dot-2"></div>
      <div className="dot dot-3"></div>
      <div className="dot dot-4"></div>
      <div className="dot dot-5"></div>
    </div>
  );
};
