import './style.css';

export const HeadingProfile = ({ svg, children, className = '' }) => {
  return (
    <div className={`${className} heading-block`}>
      {svg && <img src={svg} alt='' className='icon_block'/>}
      <h2 className='heading'>{children}</h2>
    </div>
  );
};
