import { useContext } from 'react';
import './style.css';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { updateLang } from './function';
import { useLocation, useNavigate } from 'react-router';
import { updatedPathname } from '../../Layouts/MainLayout';

export const DesktopLanguageButtons = ({
  languagesArray,
  contextLang,
  localStorageName,
  setLang,
  setIsOpenLangMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isProfile = location.pathname.includes('/profile') || location.pathname.includes('/payments-history')

  const { setProfileLang, setMainLang } = useContext(TranslationsContext);

  return (
    <>
      {languagesArray.map((lang, index) => (
        <button
          key={lang.value}
          id={`lang-item_${index}`}
          type="button"
          className={`button-lang ${
            lang.value === contextLang ? 'button-lang-active' : ''
          }`}
          onClick={() => {
            updateLang(
              lang.value,
              setProfileLang,
              setMainLang,
              setLang,
              localStorageName,
              setIsOpenLangMenu
            );
            !isProfile && !location.pathname.includes('tree') &&navigate(
              `/${lang.value}${
                updatedPathname(location) ? `/${updatedPathname(location)}` : ''
              }`
            )

            
            // console.log(
            //   `/${lang.value}${
            //     updatedPathname(location) ? `/${updatedPathname(location)}` : ''
            //   }`
            // );
            // console.log(updatedPathname(location))
            // localStorage.setItem(localStorageName, lang.value);
            // setLang(lang.value);
            // setIsOpenLangMenu(false);
          }}
        >
          {lang.value.toUpperCase()}
        </button>
      ))}
    </>
  );
};
