import { HeadingProfile } from '../../../../components/Heading/HeadingProfile';
import cash from '../../../../assets/images/cash.svg';
import { useContext } from 'react';
import { TranslationsContext } from '../../../../context/TranslationsContext';
import { postPayment } from '../../../../api/api';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../context/AuthContext';

const DEFAULT_PRO_AMOUNT = 2160;

const getSubscriptionEndDate = () => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + 1);
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate.setHours(23,59,59,999)
  return new Date(currentDate);
};

export const BankPayment = () => {
  const { getToken } = useContext(AuthContext);
  const { profileTranslations, profileLang } = useContext(TranslationsContext);

  const orderData = [
    {
      id: 1,
      label: profileTranslations?.profile?.amount,
      data: `${DEFAULT_PRO_AMOUNT} RUB`,
    },
    {
      id: 2,
      label: profileTranslations?.profile?.date,
      data: new Date().toLocaleDateString(profileLang, { year: 'numeric',
      month: 'long',
      day: 'numeric',})//.toLocaleDateString(),
    },
    {
      id: 3,
      label: profileTranslations?.profile?.rateTerm,
      data: getSubscriptionEndDate().toLocaleDateString(profileLang, { year: 'numeric',
      month: 'long',
      day: 'numeric',})//.toLocaleDateString(),
    },
  ];

  const paymentHandler = async () => {
    try {
      const data = {
        user_id: getToken(),
        amount: DEFAULT_PRO_AMOUNT,
        created: new Date().toISOString(),
        valid_until: getSubscriptionEndDate().toISOString(),
      };
      const formURL = await postPayment(data, profileLang);
      
      window.location.href = formURL.data.form_url;
    } catch (e) {
      toast.error(e?.message || e?.response?.data?.errors?.[0]?.detail);
    }
  };

  return (
    <section className="mb-25">
      <HeadingProfile svg={cash} className="mb-15">
        {profileTranslations?.profile?.proAccountTitle}
      </HeadingProfile>
      <div className="block_container block_container_double">
        <div className="pro-description">
          <HeadingProfile className="mb-15">
            {profileTranslations?.profile?.proAccountListTitle}
          </HeadingProfile>
          <ul>
            {profileTranslations?.profile?.proAccountListItems.map((data, index) => (
              <li key={index}>{data}</li>
            ))}
          </ul>
        </div>
        <div className="pro-payment">
          <div>
            <HeadingProfile className="mb-15">
              {profileTranslations?.profile?.orderDataTitle}
            </HeadingProfile>
            {orderData?.map((field) => (
              <div className="pro-payment-items" key={field.id}>
                <span className="subtitle">{field.label}:</span>{' '}
                <span>{field.data}</span>
              </div>
            ))}
          </div>
          <div className="pro-payment-button-block">
            <button
              type="button"
              className="button-pay mb-15"
              onClick={paymentHandler}
            >
              {profileTranslations?.profile?.bankPayment}
            </button>
            <div>{profileTranslations?.profile?.bankPaymentDescription}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
