import { Helmet } from 'react-helmet-async';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { useContext } from 'react';

export const MainHelmet = () => {
  const { mainLang, fullTranslations } = useContext(TranslationsContext);

  return (
    <Helmet htmlAttributes={{ lang: mainLang, amp: undefined }}>
      <title>{fullTranslations?.head?.mainPage?.title}</title>
      <meta
        name="description"
        content={fullTranslations?.head?.mainPage?.description}
      />

      <meta property="og:url" content="https://www.mykin.in/" />
      <meta property="og:image" content="https://www.mykin.in/preview.png" />
      <meta property="og:image:width" content="663" />
      <meta property="og:image:height" content="649" />
      <meta
        property="og:title"
        content={fullTranslations?.head?.mainPage?.title}
      />
      <meta
        property="og:description"
        content={fullTranslations?.head?.mainPage?.ogDescription}
      />
      <meta name="twitter:image" content="https://www.mykin.in/preview.png" />
      <meta
        name="twitter:title"
        content={fullTranslations?.head?.mainPage?.title}
      />
      <meta
        name="twitter:description"
        content={fullTranslations?.head?.mainPage?.ogDescription}
      />
      <meta property="vk:url" content="https://www.mykin.in" />

      <meta property="vk:image" content="https://www.mykin.in/preview.png" />
      <meta
        property="vk:title"
        content={fullTranslations?.head?.mainPage?.title}
      />
      <meta
        property="vk:description"
        content={fullTranslations?.head?.mainPage?.ogDescription}
      />
    </Helmet>
  );
};
