import { PortalModal } from '../../../components/PortalModal/PortalModal';
import closeSVG from '../../../assets/images/close-icon.svg';
import { Slider } from './Slider/Slider';
import { ModalSlider, excludeOnCloseClassNames } from './Slider/ModalSlider';
import { useContext, useState } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { defaultImg } from '../../../functions/drawTree';

export const Modal = ({ modalIsOpened, setModalIsOpened, personData }) => {
  const { fullTranslations } = useContext(TranslationsContext);

  let {
    photoPath,
    name = '-',
    imageList,
    dateOfBirth = '-',
    placeOfBirth = '-',
    gender = '-',
    onlyKnowYear,
  } = personData;

  dateOfBirth =
    typeof dateOfBirth === 'number'
      ? onlyKnowYear
        ? new Date(dateOfBirth * 1000).getFullYear()
        : new Date(dateOfBirth * 1000).toLocaleDateString()
      : dateOfBirth;

  const [openModalSlider, setOpenModalSlider] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  return (
    <PortalModal
      childrenClassName={'js-modal-dialog'}
      className={'modal-c-backdrop'}
      isOpen={modalIsOpened}
      onClose={() => setModalIsOpened(false)}
      // excludeOnCloseClassNames={excludeOnCloseClassNames}
    >
      <div className={`${modalIsOpened ? '' : 'hide'}`}>
        <div className="user js-modal-dialog" id="user-info">
          <button
            type="button"
            className={'close-button'}
            onClick={() => setModalIsOpened(false)}
          >
            <img src={closeSVG} alt={'close modal'} />
          </button>
          <div className="user__avatar">
            <img src={photoPath || defaultImg?.[gender]} className="modal_img" alt="" />
          </div>
          <div className="user__content">
            <div className="user__name">{name}</div>
            <ul className="user__info">
              <li className="user__info--item">
                <div className="user__info--label">
                  {fullTranslations?.tree?.dateOfBirth}
                </div>
                <div className="user__info--value">{dateOfBirth}</div>
              </li>

              <li className="user__info--item">
                <div className="user__info--label">
                  {fullTranslations?.tree?.placeOfBirth}
                </div>
                <div className="user__info--value">
                  {placeOfBirth.trim().length ? placeOfBirth : '-'}
                </div>
              </li>

              <li className="user__info--item">
                <div className="user__info--label">
                  {fullTranslations?.tree?.gender}
                </div>
                <div className="user__info--value">
                {fullTranslations?.tree?.[gender] || gender}
                </div>
              </li>
            </ul>
            <div className="user__slider">
              <div className="swiper-container user-slider">
                {imageList?.length > 0 ? (
                  <Slider
                    images={imageList}
                    setInitialSlide={setInitialSlide}
                    setOpenModalSlider={setOpenModalSlider}
                  />
                ) : (
                  <div className="no-photo-plsh">
                    {fullTranslations?.tree?.noPhoto}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalSlider && (
        <ModalSlider
          openSlider={openModalSlider}
          setOpenSlider={setOpenModalSlider}
          images={imageList}
          initialSlide={initialSlide}
        />
      )}
    </PortalModal>
  );
};
