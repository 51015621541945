import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const sliderParams = {
  slidesPerView: 3,
  breakpoints: {
    650: {
      slidesPerView: 4,
    },
  }
};

export const Slider = ({ images, setInitialSlide, setOpenModalSlider }) => {
  return (
    <Swiper {...sliderParams} navigation={true} modules={[Navigation]}>
      {images.map((image, index) => (
        <SwiperSlide
          key={index}
          onClick={() => {
            setInitialSlide(index);
            setOpenModalSlider(true);
          }}
        >
          <img src={image} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
