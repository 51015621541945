import { Helmet } from 'react-helmet-async';
import { useContext } from 'react';
import {TranslationsContext} from '../../context/TranslationsContext';

export const IntroHelmet = () => {
    const { fullTranslations, mainLang } = useContext(TranslationsContext);

    return (
        <Helmet htmlAttributes={{ lang: mainLang, amp: undefined }}>
            <title>{fullTranslations?.head?.intro?.title}</title>
            <meta
                name="description"
                content={fullTranslations?.head?.intro?.description}
            />
            <meta
                property="og:url"
                content="https://mykin.in/intro"
            />
            <meta
                property="og:image"
                content="https://www.mykin.in/timeCapsulePreview.png"
            />
            <meta property="og:image:width" content="1032" />
            <meta property="og:image:height" content="997" />
            <meta
                property="og:title"
                content={fullTranslations?.head?.intro?.title}
            />
            <meta
                property="og:description"
                content={fullTranslations?.head?.intro?.ogDescription}
            />
            <meta
                name="twitter:image"
                content="https://www.mykin.in/timeCapsulePreview.png"
            />
            <meta
                name="twitter:title"
                content={fullTranslations?.head?.intro?.title}
            />
            <meta
                name="twitter:description"
                content={fullTranslations?.head?.intro?.ogDescription}
            />
            <meta
                property="vk:image"
                content="https://www.mykin.in/timeCapsulePreview.png"
            />
            <meta
                property="vk:url"
                content="https://mykin.in/intro"
            />
            <meta
                property="vk:title"
                content={fullTranslations?.head?.intro?.title}
            />
            <meta
                property="vk:description"
                content={fullTranslations?.head?.intro?.ogDescription}
            />
        </Helmet>
    );
};
