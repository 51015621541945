import MessageImg from '../../../assets/images/intro/intro-message.png';
import {ReactComponent as ListIcon} from '../../../assets/images/intro/intro-list.svg';
import {ReactComponent as VoiceIcon} from '../../../assets/images/intro/intro-voice.svg';

const HowItWorksBlock = () => {
  return (
    <div className="intro-howItWorks">
      <img src={MessageImg} alt={'message from grandpa'} className="intro-howItWorks-img"/>
      <p className={'intro-howItWorks-title_small'}>Цифровой Аватар Предка</p>
      <p className={'intro-howItWorks-title_big'}>Как Это Работает?</p>
      <p className={'intro-howItWorks-text'}>
        Все достаточно просто. Вы заполняете специально созданные разделы в приложении, внося важную информацию о том
        предке, чьего аватара вы хотите создать. Далее искусственный интеллект считывает подробные данные из
        получившегося профайла вашего&nbsp; близкого, приближая эмоциональную глубину цифрового аватара к человеку,
        которого вы знали при жизни.
      </p>
      <div className={'intro-howItWorks-block'}>
        <ListIcon className={'intro-howItWorks-block-icon'}/>
          <p className={'intro-howItWorks-text'}>
            <span className={'intro-howItWorks-text'}>Чем больше фактов из жизни умершего мы предоставим
            и чем детализированнее эти факты будут,</span><br/>
            тем более наполненным триггерными маячками получится аватар, тем более узнаваемы будут его эмоции, тем
            глубиннее получится оживить цифровую личность любимого человека.
          </p>
      </div>
      <div className={'intro-howItWorks-block'}>
        <VoiceIcon className={'intro-howItWorks-block-icon'}/>
        <p className={'intro-howItWorks-text'}>
          Помимо создания цифровой личности близкого можно загрузить в приложение хотя бы минимальный фрагмент его
          голоса и программа воссоздаст голос предка для прослушивания сообщений.
        </p>
      </div>
      <p className={'intro-howItWorks-text'}>
        Каждый из этих продуктов для всего мира новый и очень дорогой. К примеру, в Кореи создание Цифрового Аватара
        предка стоит 27.000$, а отдельно Воссоздание Голоса предка стоит 7.000$.
      </p>
      <p className={'intro-howItWorks-text'}>
        Создатель приложения объединил эмоциональную связь с эмоциональной памятью, представив Цифрового Аватара Предка
        и Воссоздание Голоса Предка как единое целое.
      </p>
    </div>
  );
};

export default HowItWorksBlock;
