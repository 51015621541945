import { PortalModal } from '../PortalModal/PortalModal';
import closeSVG from '../../assets/images/close-icon.svg';
import { HeadingProfile } from '../Heading/HeadingProfile';
import './style.css';
import { useContext } from 'react';
import {
  TranslationsContext,
  languages,
  fullLanguages,
} from '../../context/TranslationsContext';
import { MobileLanguageButtons } from './LanguageButtons/MobileLanguageButtons';
import { AuthContext } from '../../context/AuthContext';
import { useLocation } from 'react-router';


export const ChangeLangModal = ({
  langModalIsOpened,
  setLangModalIsOpened,
}) => {
  const location = useLocation();
  const { isAuthState } = useContext(AuthContext);

  const {
    profileLang,
    setProfileLang,
    profileTranslations,
    mainLang,
    setMainLang,
    fullTranslations,
  } = useContext(TranslationsContext);

  const isProfile = location.pathname.includes('/profile') || location.pathname.includes('/payments-history')

  return (
    <PortalModal
      childrenClassName={'js-modal-dialog'}
      className={'modal-c-backdrop'}
      isOpen={langModalIsOpened}
      onClose={() => setLangModalIsOpened(false)}
    >
      <div className="overflow-hidden js-modal-dialog lang-modal">
        <button
          type="button"
          className={'close-button'}
          onClick={() => setLangModalIsOpened(false)}
        >
          <img src={closeSVG} alt={'close modal'} />
        </button>

        <HeadingProfile className="modal-heading">
          {isAuthState
            ? profileTranslations?.header?.chooseLang
            : fullTranslations?.chooseLang}
        </HeadingProfile>
        <div className="modal-buttons-block">
          <MobileLanguageButtons
            // languagesArray={isAuthState ? languages : fullLanguages}
            languagesArray={isProfile ? languages : fullLanguages }
            contextLang={isProfile ? profileLang : mainLang}
            localStorageName={
              isAuthState ? 'mykinProfileLang' : 'mykinMainLang'
            }
            setLang={isAuthState ? setProfileLang : setMainLang}
            setLangModalIsOpened={setLangModalIsOpened}
          />
        </div>
      </div>
    </PortalModal>
  );
};
