import { useContext } from 'react';
import { TranslationsContext } from '../../../context/TranslationsContext';
import { updateLang } from './function';
import { useLocation, useNavigate } from 'react-router';
import { updatedPathname } from '../../Layouts/MainLayout';

export const MobileLanguageButtons = ({
  languagesArray,
  contextLang,
  localStorageName,
  setLang,
  setLangModalIsOpened,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isProfile =
    location.pathname.includes('/profile') ||
    location.pathname.includes('/payments-history');

  const { setProfileLang, setMainLang } = useContext(TranslationsContext);

  return languagesArray.map((lang) => (
    <button
      key={lang.value}
      type="button"
      className={`button-lang-mobile ${
        lang.value === contextLang ? 'button-lang-mobile-active' : ''
      }`}
      onClick={() => {
        updateLang(
          lang.value,
          setProfileLang,
          setMainLang,
          setLang,
          localStorageName,
          setLangModalIsOpened
        );
        !isProfile && !location.pathname.includes('tree') &&
          navigate(
            `/${lang.value}${
              updatedPathname(location) ? `/${updatedPathname(location)}` : ''
            }`
          );
        // setLang(lang.value);
        // localStorage.setItem(localStorageName, lang.value);
        // setLangModalIsOpened(false);
      }}
    >
      {lang.description}
    </button>
  ));
};
