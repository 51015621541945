export const ErrorImage = () => {
  return (
    <svg
      width="437"
      height="147"
      viewBox="0 0 437 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M185.85 40.0222C185.85 38.438 187.132 37.1539 188.713 37.1539H204.763C206.344 37.1539 207.626 38.438 207.626 40.0221V54.7372C207.626 56.3213 206.344 57.6055 204.763 57.6055H188.713C187.132 57.6055 185.85 56.3213 185.85 54.7372V40.0222Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.763 37.8709H188.713C187.527 37.8709 186.565 38.8341 186.565 40.0222V54.7372C186.565 55.9253 187.527 56.8884 188.713 56.8884H204.763C205.949 56.8884 206.911 55.9253 206.911 54.7372V40.0221C206.911 38.8341 205.949 37.8709 204.763 37.8709ZM188.713 37.1539C187.132 37.1539 185.85 38.438 185.85 40.0222V54.7372C185.85 56.3213 187.132 57.6055 188.713 57.6055H204.763C206.344 57.6055 207.626 56.3213 207.626 54.7372V40.0221C207.626 38.438 206.344 37.1539 204.763 37.1539H188.713Z"
        fill="#799BFF"
      />
      <path
        d="M227.362 40.0222C227.362 38.438 228.643 37.1539 230.225 37.1539H246.275C247.856 37.1539 249.138 38.438 249.138 40.0221V54.7372C249.138 56.3213 247.856 57.6055 246.275 57.6055H230.225C228.643 57.6055 227.362 56.3213 227.362 54.7372V40.0222Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.275 37.8709H230.225C229.039 37.8709 228.077 38.8341 228.077 40.0222V54.7372C228.077 55.9253 229.039 56.8884 230.225 56.8884H246.275C247.461 56.8884 248.423 55.9253 248.423 54.7372V40.0221C248.423 38.8341 247.461 37.8709 246.275 37.8709ZM230.225 37.1539C228.643 37.1539 227.362 38.438 227.362 40.0222V54.7372C227.362 56.3213 228.643 57.6055 230.225 57.6055H246.275C247.856 57.6055 249.138 56.3213 249.138 54.7372V40.0221C249.138 38.438 247.856 37.1539 246.275 37.1539H230.225Z"
        fill="#799BFF"
      />
      <path
        d="M238.25 93.8782C238.25 92.2941 239.532 91.0099 241.113 91.0099H257.164C258.745 91.0099 260.027 92.2941 260.027 93.8782V108.593C260.027 110.177 258.745 111.462 257.164 111.462H241.113C239.532 111.462 238.25 110.177 238.25 108.593V93.8782Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.164 91.7269H241.113C239.927 91.7269 238.966 92.6901 238.966 93.8782V108.593C238.966 109.781 239.927 110.744 241.113 110.744H257.164C258.35 110.744 259.311 109.781 259.311 108.593V93.8782C259.311 92.6901 258.35 91.7269 257.164 91.7269ZM241.113 91.0099C239.532 91.0099 238.25 92.2941 238.25 93.8782V108.593C238.25 110.177 239.532 111.462 241.113 111.462H257.164C258.745 111.462 260.027 110.177 260.027 108.593V93.8782C260.027 92.2941 258.745 91.0099 257.164 91.0099H241.113Z"
        fill="#799BFF"
      />
      <path
        d="M174.961 93.8782C174.961 92.2941 176.243 91.0099 177.824 91.0099H193.875C195.456 91.0099 196.738 92.2941 196.738 93.8782V108.593C196.738 110.177 195.456 111.462 193.875 111.462H177.824C176.243 111.462 174.961 110.177 174.961 108.593V93.8782Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.875 91.7269H177.824C176.638 91.7269 175.677 92.6901 175.677 93.8782V108.593C175.677 109.781 176.638 110.744 177.824 110.744H193.875C195.061 110.744 196.022 109.781 196.022 108.593V93.8782C196.022 92.6901 195.061 91.7269 193.875 91.7269ZM177.824 91.0099C176.243 91.0099 174.961 92.2941 174.961 93.8782V108.593C174.961 110.177 176.243 111.462 177.824 111.462H193.875C195.456 111.462 196.738 110.177 196.738 108.593V93.8782C196.738 92.2941 195.456 91.0099 193.875 91.0099H177.824Z"
        fill="#799BFF"
      />
      <path
        d="M189.933 79.2211C189.933 77.637 191.215 76.3529 192.796 76.3529H242.532C244.113 76.3529 245.395 77.637 245.395 79.2211V93.9362C245.395 95.5203 244.113 96.8045 242.532 96.8045H192.796C191.215 96.8045 189.933 95.5203 189.933 93.9362V79.2211Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.532 77.0699H192.796C191.61 77.0699 190.649 78.0331 190.649 79.2211V93.9362C190.649 95.1243 191.61 96.0874 192.796 96.0874H242.532C243.718 96.0874 244.68 95.1243 244.68 93.9362V79.2211C244.68 78.0331 243.718 77.0699 242.532 77.0699ZM192.796 76.3529C191.215 76.3529 189.933 77.637 189.933 79.2211V93.9362C189.933 95.5203 191.215 96.8045 192.796 96.8045H242.532C244.113 96.8045 245.395 95.5203 245.395 93.9362V79.2211C245.395 77.637 244.113 76.3529 242.532 76.3529H192.796Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.897 22.3199V125.488H284.103V22.3199H152.897ZM137.149 0.807696C133.986 0.807696 131.422 3.37605 131.422 6.54428V141.263C131.422 144.432 133.986 147 137.149 147H299.851C303.014 147 305.577 144.432 305.577 141.263V6.54428C305.577 3.37605 303.014 0.807696 299.851 0.807696H137.149Z"
        fill="#799BFF"
      />
      <path
        d="M0 5.73659C0 2.56836 2.56384 0 5.72651 0H24.1056C27.2683 0 29.8321 2.56836 29.8321 5.73659V65.3403C29.8321 68.5086 27.2683 71.0769 24.1056 71.0769H5.72651C2.56385 71.0769 0 68.5086 0 65.3403V5.73659Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1056 0.717073H5.72651C2.95918 0.717073 0.715813 2.96439 0.715813 5.73659V65.3403C0.715813 68.1125 2.95918 70.3598 5.72651 70.3598H24.1056C26.8729 70.3598 29.1163 68.1125 29.1163 65.3403V5.73659C29.1163 2.96439 26.8729 0.717073 24.1056 0.717073ZM5.72651 0C2.56384 0 0 2.56836 0 5.73659V65.3403C0 68.5086 2.56385 71.0769 5.72651 71.0769H24.1056C27.2683 71.0769 29.8321 68.5086 29.8321 65.3403V5.73659C29.8321 2.56836 27.2683 0 24.1056 0H5.72651Z"
        fill="#799BFF"
      />
      <path
        d="M69.3395 8.15966C69.3395 4.99143 71.9033 2.42307 75.066 2.42307H93.4451C96.6077 2.42307 99.1716 4.99143 99.1716 8.15965V138.033C99.1716 141.201 96.6077 143.769 93.4451 143.769H75.066C71.9033 143.769 69.3395 141.201 69.3395 138.033V8.15966Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.4451 3.14014H75.066C72.2986 3.14014 70.0553 5.38746 70.0553 8.15966V138.033C70.0553 140.805 72.2986 143.052 75.066 143.052H93.4451C96.2124 143.052 98.4557 140.805 98.4557 138.033V8.15965C98.4557 5.38745 96.2124 3.14014 93.4451 3.14014ZM75.066 2.42307C71.9033 2.42307 69.3395 4.99143 69.3395 8.15966V138.033C69.3395 141.201 71.9033 143.769 75.066 143.769H93.4451C96.6077 143.769 99.1716 141.201 99.1716 138.033V8.15965C99.1716 4.99143 96.6077 2.42307 93.4451 2.42307H75.066Z"
        fill="#799BFF"
      />
      <path
        d="M21.0457 83.1923C17.883 83.1923 15.3192 80.6239 15.3192 77.4557V59.0443C15.3192 55.876 17.883 53.3077 21.0457 53.3077H80.5447C83.7073 53.3077 86.2712 55.876 86.2712 59.0443V77.4557C86.2712 80.6239 83.7074 83.1923 80.5447 83.1923H21.0457Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.035 59.0443V77.4557C16.035 80.2279 18.2783 82.4752 21.0457 82.4752H80.5447C83.312 82.4752 85.5554 80.2279 85.5554 77.4557V59.0443C85.5554 56.2721 83.312 54.0247 80.5447 54.0247H21.0457C18.2783 54.0247 16.035 56.2721 16.035 59.0443ZM15.3192 77.4557C15.3192 80.6239 17.883 83.1923 21.0457 83.1923H80.5447C83.7074 83.1923 86.2712 80.6239 86.2712 77.4557V59.0443C86.2712 55.876 83.7073 53.3077 80.5447 53.3077H21.0457C17.883 53.3077 15.3192 55.876 15.3192 59.0443V77.4557Z"
        fill="#799BFF"
      />
      <path
        d="M337.828 5.73659C337.828 2.56836 340.392 0 343.555 0H361.934C365.097 0 367.661 2.56836 367.661 5.73659V65.3403C367.661 68.5086 365.097 71.0769 361.934 71.0769H343.555C340.392 71.0769 337.828 68.5086 337.828 65.3403V5.73659Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M361.934 0.717073H343.555C340.788 0.717073 338.544 2.96439 338.544 5.73659V65.3403C338.544 68.1125 340.788 70.3598 343.555 70.3598H361.934C364.701 70.3598 366.945 68.1125 366.945 65.3403V5.73659C366.945 2.96439 364.701 0.717073 361.934 0.717073ZM343.555 0C340.392 0 337.828 2.56836 337.828 5.73659V65.3403C337.828 68.5086 340.392 71.0769 343.555 71.0769H361.934C365.097 71.0769 367.661 68.5086 367.661 65.3403V5.73659C367.661 2.56836 365.097 0 361.934 0H343.555Z"
        fill="#799BFF"
      />
      <path
        d="M407.168 8.15966C407.168 4.99143 409.732 2.42307 412.894 2.42307H431.273C434.436 2.42307 437 4.99143 437 8.15965V138.033C437 141.201 434.436 143.769 431.273 143.769H412.894C409.732 143.769 407.168 141.201 407.168 138.033V8.15966Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.273 3.14014H412.894C410.127 3.14014 407.884 5.38746 407.884 8.15966V138.033C407.884 140.805 410.127 143.052 412.894 143.052H431.273C434.041 143.052 436.284 140.805 436.284 138.033V8.15965C436.284 5.38745 434.041 3.14014 431.273 3.14014ZM412.894 2.42307C409.732 2.42307 407.168 4.99143 407.168 8.15966V138.033C407.168 141.201 409.732 143.769 412.894 143.769H431.273C434.436 143.769 437 141.201 437 138.033V8.15965C437 4.99143 434.436 2.42307 431.273 2.42307H412.894Z"
        fill="#799BFF"
      />
      <path
        d="M358.874 83.1923C355.711 83.1923 353.148 80.6239 353.148 77.4557V59.0443C353.148 55.876 355.711 53.3077 358.874 53.3077H418.373C421.536 53.3077 424.1 55.876 424.1 59.0443V77.4557C424.1 80.6239 421.536 83.1923 418.373 83.1923H358.874Z"
        fill="#799BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.863 59.0443V77.4557C353.863 80.2279 356.107 82.4752 358.874 82.4752H418.373C421.14 82.4752 423.384 80.2279 423.384 77.4557V59.0443C423.384 56.2721 421.14 54.0247 418.373 54.0247H358.874C356.107 54.0247 353.863 56.2721 353.863 59.0443ZM353.148 77.4557C353.148 80.6239 355.711 83.1923 358.874 83.1923H418.373C421.536 83.1923 424.1 80.6239 424.1 77.4557V59.0443C424.1 55.876 421.536 53.3077 418.373 53.3077H358.874C355.711 53.3077 353.148 55.876 353.148 59.0443V77.4557Z"
        fill="#799BFF"
      />
    </svg>
  );
};
