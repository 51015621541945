import { AuthLayout } from '../../components/Layouts/AuthLayout';
import { AnotherPayments } from './components/AnotherPayments/AnotherPayments';
import { BankPayment } from './components/BankPayment/BankPayment';
import { Instructions } from './components/Instruction/Instructions';
import { PersonalData } from './components/PersonalData/PersonalData';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from '../../components/Loaders/Spinner';
import { AuthContext } from '../../context/AuthContext';
import { getUserInfo } from '../../api/api';
import { ProfileHelmet } from './components/ProfileHelmet';

export const Profile = () => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    const userInfoPromise = new Promise(function (resolve) {
      const result = getUserInfo(getToken());
      resolve(result);
    });
    userInfoPromise.then((value) => {
      setUserData(value);
      setIsLoading(false);
    });
  }, [getToken]);

  return (
    <>
      <ProfileHelmet />
      <AuthLayout>
        {isLoading ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : (
          <>
            <PersonalData userData={userData} />
            <Instructions />
            <BankPayment />
            <AnotherPayments />
          </>
        )}
      </AuthLayout>
    </>
  );
};
