import { languages } from '../../../context/TranslationsContext';

export const updateLang = (
  chosenLang,
  setProfileLang,
  setMainLang,
  setLang,
  localStorageName,
  closeMenu
) => {
  const includeLanguage = languages.find(
    (item) => item.value.toLowerCase() === chosenLang.toLowerCase()
  );
  if (includeLanguage?.value) {
    setProfileLang(chosenLang);
    localStorage.setItem('mykinProfileLang', chosenLang);
    setMainLang(chosenLang);
    sessionStorage.setItem('mykinMainLang', chosenLang);
  } else if (!includeLanguage) {
    setProfileLang('en');
    localStorage.setItem('mykinProfileLang', 'en');
    setMainLang(chosenLang);
    sessionStorage.setItem('mykinMainLang', chosenLang);
  } else {
    sessionStorage.setItem(localStorageName, chosenLang);
    setLang(chosenLang);
  }
  closeMenu(false);
};
