import { useContext, useEffect, useState } from 'react';
import { AuthLayout } from '../../components/Layouts/AuthLayout';
import { Table } from '../../components/Table/Table';
import './style.css';
import { Spinner } from '../../components/Loaders/Spinner';
import { AuthContext } from '../../context/AuthContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { toast } from 'react-toastify';
import { getPaymentsHistory } from '../../api/api';
import { DocImage } from './components/DocImage';
import { HistoryHelmet } from './components/HistoryHelmet';

const addDay = (date, dayAmount) => {
  var D = new Date(date);
  D.setDate(D.getDate() + dayAmount);
  return D;
};

export const PaymentsHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentsData, setPaymentsData] = useState([]);

  const { getToken } = useContext(AuthContext);
  const { profileTranslations, profileLang } = useContext(TranslationsContext);

  const tableConfig = [
    {
      id: 1,
      title: 'ID',
      cell: ({ payment_id }) => payment_id,
    },
    {
      id: 2,
      title: profileTranslations?.history?.date,
      cell: ({ created }) => new Date(created).toLocaleString(),
    },
    {
      id: 3,
      title: profileTranslations?.history?.amount,
      cell: ({ amount }) => (
        <span style={{ color: '#799BFF' }}>{amount} RUB</span>
      ),
    },
    {
      id: 4,
      title: profileTranslations?.history?.validTo,
      cell: ({ status, valid_until }) =>
        status === 'CONFIRMED'
          ? new Date(valid_until).toLocaleDateString()
          : '-',
    },
    {
      id: 5,
      title: profileTranslations?.history?.status,
      cell: ({ status, created }) => (
        <div
          className={`status-item ${
            status === 'FORM_SHOWED' || status === 'NEW'
              ? new Date(addDay(created, 1)) < new Date()
                ? `status-item-${statuses?.['CANCELED']?.color}`
                : `status-item-${statuses?.[status]?.color}`
              : statuses?.[status]?.color
              ? `status-item-${statuses?.[status]?.color}`
              : ''
          }`}
        >
          {status === 'FORM_SHOWED' || status === 'NEW'
            ? new Date(addDay(created, 1)) < new Date()
              ? statuses?.['CANCELED']?.text
              : statuses?.[status]?.text
            : statuses?.[status]?.text || profileTranslations?.history?.unknown}
          {/* {statuses?.[status]?.text || translations?.history?.unknown} */}
        </div>
      ),
    },
  ];

  const statuses = {
    NEW: {
      text: profileTranslations?.history?.inProcess,
      color: 'yellow',
    },
    FORM_SHOWED: {
      text: profileTranslations?.history?.inProcess,
      color: 'yellow',
    },
    CONFIRMED: {
      text: profileTranslations?.history?.payed,
      color: 'green',
    },
    AUTH_FAIL: { text: profileTranslations?.history?.rejected, color: 'red' },
    REJECTED: { text: profileTranslations?.history?.rejected, color: 'red' },
    CANCELED: { text: profileTranslations?.history?.canceled, color: 'red' },
    DEADLINE_EXPIRED: {
      text: profileTranslations?.history?.canceled,
      color: 'red',
    },
    '3DS_CHECKING': {
      text: profileTranslations?.history?.canceled,
      color: 'red',
    },
    UNKNOWN: { text: profileTranslations?.history?.unknown, color: '' },
  };

  useEffect(() => {
    const getPaymentsData = async () => {
      setIsLoading(true);
      try {
        const data = await getPaymentsHistory(getToken());
        setPaymentsData(data);
      } catch (error) {
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getPaymentsData();
  }, [getToken]);

  return (
    <>
      <HistoryHelmet />
      <AuthLayout>
        {isLoading ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : paymentsData.length > 0 ? (
          <div className={'table-container'}>
            <Table config={tableConfig} data={paymentsData} />
          </div>
        ) : (
          <div className={'table-empty'}>
            <DocImage />
            <div className={'table-empty-text'}>
              {profileTranslations?.history?.emptyPayments}
            </div>
          </div>
        )}
      </AuthLayout>
    </>
  );
};
