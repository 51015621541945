import { useLocation } from 'react-router';
import { TranslationsContext } from '../../context/TranslationsContext';
import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

export const Nav = () => {
  const location = useLocation();
  const { mainLang, profileTranslations, fullTranslations } =
    useContext(TranslationsContext);

  return (
    <nav>
      {location.pathname.includes('/profile') ||
      location.pathname.includes('/payments-history') ? (
        <>
          <NavLink to={`/profile`} className={`nav-item nav-item-mob-underline`}>
            {profileTranslations?.header?.profile}
          </NavLink>
          <NavLink to={`/payments-history`} className={`nav-item nav-item-mob-underline`}>
            {profileTranslations?.header?.payments}
          </NavLink>
        </>
      ) : (
        <>
          <Link to={`/${mainLang}`} className={`main-header-nav-mob-hidden nav-item ${(location.pathname === `/${mainLang}` || location.pathname === `/`) ? 'active' : ''}`}>
            {fullTranslations?.header?.aboutUs}
          </Link>
          <Link
            to={`/${mainLang}/services/time-capsule`}
            className={`main-header-nav-mob-hidden nav-item ${location.pathname.includes('/services') ? 'active' : ''}`}
          >
            {fullTranslations?.header?.services}
          </Link>
        </>
      )}
    </nav>
  );
};
