import { Link } from 'react-router-dom';
import './style.css';

export const TransparentLink = ({ children, className = '', toPath="" }) => {
  return (
    <Link to={toPath} className={className}>
      {children}
    </Link>
  );
};
