import { useContext } from 'react';
import { MainLayout } from '../../components/Layouts/MainLayout';
import { ServicesNav } from './components/ServicesNav';
import './style.css';
import { TranslationsContext } from '../../context/TranslationsContext';
import { InstructionsBlock } from '../Main/components/InstructionBlock/InstructionsBlock';
import { HeadingServices } from '../../components/Heading/HeadingServices';
import { DownloadLinks } from '../../components/DownloadLinks/DownloadLinks';
import bg1 from '../../assets/images/services/avatar-bg1.webp';
import bg1Mob from '../../assets/images/services/avatar-bg1-mob.webp';
import bg2 from '../../assets/images/services/phone-avatar-creation-ru.webp';
import bg3 from '../../assets/images/services/avatar-bg3.webp';
import { DigitalAvatarHelmet } from './components/DigitalAvatarHelmet';
import {TransparentButton} from '../../components/Button/TransparentButton';
import {Link} from 'react-router-dom';

export const ServicesDigitalAvatar = () => {
  const { fullTranslations, mainLang } = useContext(TranslationsContext);

  return (
    <>
      <DigitalAvatarHelmet />

      <MainLayout>
        <main className="services-container">
          <div className="container services-nav-container">
            <ServicesNav />
          </div>

          <div className="container">
            <section className="services-title-block services-title-block-avatar">
              <div className="services-title-block-content">
                <h1>{fullTranslations?.digitalAvatar?.title}</h1>
                <div>{fullTranslations?.digitalAvatar?.description}</div>
                <Link
                  to={`/${mainLang}/intro`}>
                  <TransparentButton className={'services-more-button'}>
                    {fullTranslations?.timeCapsule?.more}
                  </TransparentButton>
                </Link>
              </div>
            </section>
            <section className="services-section-avatar services-section-avatar-gray">
              <div className="services-section-avatar-content">
                <HeadingServices>
                  {fullTranslations?.digitalAvatar?.aliveTitle}
                </HeadingServices>
                {fullTranslations?.digitalAvatar?.aliveDescriptionItems?.map(
                    (item, index) => (
                        <p key={index}>{item}</p>
                    )
                )}
              </div>
              <div className="services-section-avatar-content-img">
                {window.screen.width > 900 ? (
                    <img src={bg1} width="171" height="436" alt=""/>
                ) : (
                    <img src={bg1Mob} width="295" height="121" alt=""/>
                )}
              </div>
            </section>
            <section className="services-section-avatar services-section-avatar-blue">
              <div className="services-section-avatar-content-img">
                <img src={fullTranslations?.digitalAvatar?.phoneImg} width="216" height="456" alt=""/>
              </div>
              <div className="services-section-avatar-content">
                <HeadingServices>
                  <span className="services-section-avatar-content-underline">
                    Mykin
                  </span>{' '}
                  {fullTranslations?.digitalAvatar?.mykinAvatarTitleFirst}{' '}
                  <span className="services-section-avatar-content-underline-arrow">
                    {' '}
                    {fullTranslations?.digitalAvatar?.mykinAvatarTitleUnderline}
                  </span>{' '}
                  {fullTranslations?.digitalAvatar?.mykinAvatarTitleLast}
                </HeadingServices>
                {fullTranslations?.digitalAvatar?.mykinAvatarDescriptionItems?.map(
                    (item, index) => (
                        <p key={index}>{item}</p>
                    )
                )}
              </div>
            </section>
            <section className="services-section-avatar services-section-avatar-gray">
              <div className="services-section-avatar-content services-section-avatar-content-narrow">
                <div className="services-section-avatar-content-vers">
                  {fullTranslations?.digitalAvatar?.version}
                </div>
                <HeadingServices>
                  <span className="services-section-avatar-content-underline-long">
                    {fullTranslations?.digitalAvatar?.downloadTitleUnderline}
                  </span>{' '}
                  {fullTranslations?.digitalAvatar?.downloadTitleLast}{' '}
                  <span className="services-section-avatar-content-underline">
                    Mykin
                  </span>
                </HeadingServices>
                <p>
                  {fullTranslations?.digitalAvatar?.mykinAvatarDescriptionItem}
                </p>
                <DownloadLinks isBlack={true}/>
              </div>
              <div className="services-section-avatar-content-img">
                <img src={bg3} width="375" height="347" alt=""/>
              </div>
            </section>
            <InstructionsBlock/>
          </div>
        </main>
      </MainLayout>
    </>
  );
};
