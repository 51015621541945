import { useContext, useRef, useState } from 'react';
import {
  TranslationsContext,
  fullLanguages,
  languages,
} from '../../context/TranslationsContext';
import { AuthContext } from '../../context/AuthContext';
import { DesktopLanguageButtons } from './LanguageButtons/DesktopLanguageButtons';
import { useClickOutside } from '@react-hookz/web';
import arrow from '../../assets/images/arrow-bottom.svg';
import { useLocation } from 'react-router';

export const ChangeLangMenu = () => {
  const location = useLocation();

  const { mainLang, setMainLang, profileLang, setProfileLang } =
    useContext(TranslationsContext);
  const { isAuthState } = useContext(AuthContext);

  const langMenuRef = useRef(null);

  const [isOpenLangMenu, setIsOpenLangMenu] = useState(false);

  const isProfile = location.pathname.includes('/profile') || location.pathname.includes('/payments-history')

  useClickOutside(langMenuRef, (e) => {
    if (!(e.target.id === 'lang-menu' || e.target.id.includes('lang-item'))) {
      setIsOpenLangMenu(false);
    }
  });

  return (
    <div>
      <button
        ref={langMenuRef}
        type="button"
        className={`lang-button ${isOpenLangMenu ? 'lang-button-active' : ''}`}
        onClick={() => setIsOpenLangMenu(!isOpenLangMenu)}
      >
        <span>{isProfile ? profileLang : mainLang}</span>
        <img src={arrow} alt='open menu'/>
      </button>

      {isOpenLangMenu && (
        <div className="lang-block-desktop" id="lang-menu">
          <DesktopLanguageButtons
            // languagesArray={isAuthState ? languages : fullLanguages}
            languagesArray={isProfile ? languages : fullLanguages }
            contextLang={isProfile ? profileLang : mainLang}
            localStorageName={
              isAuthState ? 'mykinProfileLang' : 'mykinMainLang'
            }
            setLang={isAuthState ? setProfileLang : setMainLang}
            setIsOpenLangMenu={setIsOpenLangMenu}
          />
        </div>
      )}
    </div>
  );
};
