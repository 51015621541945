import { PortalModal } from '../../../../components/PortalModal/PortalModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, A11y, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import closeSVG from '../../../../assets/images/close-icon.svg';

export const excludeOnCloseClassNames = [
  'swiper-pagination',
  'swiper-button-next',
  'swiper-button-prev',
  'swiper-button-disabled',
  'js-no-close',
];

export const ModalSlider = ({
  openSlider,
  setOpenSlider,
  initialSlide,
  images,
}) => {
  return (
    <PortalModal
      // childrenClassName={'js-modal-dialog'}
      className={'modal-c-backdrop-double'}
      isOpen={openSlider}
      onClose={() => setOpenSlider(false)}
      excludeOnCloseClassNames={excludeOnCloseClassNames}
    >
      <button type="button" className={'close-button'}>
        <img src={closeSVG} alt="Close" loading="lazy" />
      </button>

      <Swiper
        slidesPerView={1}
        initialSlide={initialSlide}
        zoom={true}
        centeredSlides={true}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, A11y, Zoom]}
        className={`modal-swiper`}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-zoom-container">
              <img
                src={image}
                alt="photo"
                className={`js-no-close`}
                loading="lazy"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </PortalModal>
  );
};
