import {useContext} from 'react';
import './style.css';
import {DownloadLinks} from '../../components/DownloadLinks/DownloadLinks';
import GrandfatherImg from '../../assets/images/services/connection/grandfather-old.png';
import GrandfatherImgSmall from '../../assets/images/services/connection/grandfather-small.png';
import GrandfatherWithGirlImg from '../../assets/images/services/connection/grandfather-with-girl.png';
import GrandfatherWithGirlImgSmall from '../../assets/images/services/connection/grandfather-with-girl-small.png';
import {Link} from 'react-router-dom';
import {TranslationsContext} from '../../context/TranslationsContext';
import {MainLayout} from '../../components/Layouts/MainLayout';
import {ShortStoryHelmet} from './ShortStoryHelmet';

const ShortStory = () => {
    const {fullTranslations, mainLang} = useContext(TranslationsContext);

    return (
        <>
            <ShortStoryHelmet/>

            <MainLayout>
                <main className="story-main">
                    <div className="container">
                        <div className={'story-container'}>
                            <div className={'story-content'}>
                                {fullTranslations.shortStory?.content}
                            </div>
                            <div className={'story-message-img'}>
                                <img
                                    src={fullTranslations.shortStory?.messageImg?.big}
                                    srcSet={`${fullTranslations.shortStory?.messageImg?.small} 400w, ${fullTranslations.shortStory?.messageImg?.big} 1000w`}
                                    sizes={'(max-width: 450px)'}
                                />
                            </div>
                            <div className={'story-post-scriptum'}>
                                <p>P.S.</p>
                                <p>
                                    {fullTranslations.shortStory?.postScriptum}
                                </p>
                            </div>
                            <DownloadLinks isBlack/>
                            <div className={'story-app-info'}>
                                <p>
                                    {fullTranslations.shortStory?.appInfo}
                                </p>
                            </div>
                            <div className={'story-img'}>
                                <img
                                    src={GrandfatherImg}
                                    srcSet={`${GrandfatherImgSmall} 500w, ${GrandfatherImg} 2000w`}
                                />
                                <p>
                                    {fullTranslations.shortStory?.grandfatherImgDescription}
                                </p>
                            </div>
                            <div className={'story-img'}>
                                <img
                                    src={GrandfatherWithGirlImg}
                                    srcSet={`${GrandfatherWithGirlImgSmall} 500w, ${GrandfatherWithGirlImg} 2000w`}
                                />
                                <p>
                                    {fullTranslations.shortStory?.grandfatherWithGirlImgDescription}
                                </p>
                            </div>
                        </div>


                    </div>
                </main>


            </MainLayout>
        </>
    );
};

export default ShortStory;
