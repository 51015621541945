import { useContext } from 'react';
import { TranslationsContext } from '../../../../context/TranslationsContext';
import Images from './Images';

export const InstructionsBlock = () => {
  const { fullTranslations } = useContext(TranslationsContext);

  return (
    <section className="instructions">
      <Images />
      <div className="instructions-content">
        <h2 className="instructions-content-title">
          {fullTranslations?.main?.instructionTitle}
        </h2>
        <ol className="instructions-content-list">
          {fullTranslations?.main?.instructionItems?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      </div>
    </section>
  );
};
