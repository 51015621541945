export const DocImage = () => {
  return (
    <svg
      width="191"
      height="194"
      viewBox="0 0 191 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M191 27.2773H181.928V172.795H191V27.2773Z" fill="#799BFF" />
      <path
        d="M181.927 172.795H172.795V181.927H181.927V172.795Z"
        fill="#799BFF"
      />
      <path
        d="M181.927 18.2051H172.795V27.2776H181.927V18.2051Z"
        fill="#799BFF"
      />
      <path d="M172.796 181.928H18.2051V191H172.796V181.928Z" fill="#799BFF" />
      <path
        d="M154.65 145.519H36.4092V154.651H154.65V145.519Z"
        fill="#799BFF"
      />
      <path d="M154.65 109.168H36.4092V118.24H154.65V109.168Z" fill="#799BFF" />
      <path
        d="M154.65 72.7588H109.168V81.8313H154.65V72.7588Z"
        fill="#799BFF"
      />
      <path
        d="M154.65 45.4814H109.168V54.5539H154.65V45.4814Z"
        fill="#799BFF"
      />
      <path
        d="M45.4816 18.2047V81.8316H54.5541V72.7591H63.6863V63.6866H72.7588V72.7591H81.8313V81.8316H90.9635V18.2047H172.795V9.0725H81.8313V0H27.277V9.0725H9.07227V18.2047H45.4816ZM63.6863 9.0725H72.7588V18.2047H81.8313V36.4094H72.7588V18.2047H63.6863V9.0725Z"
        fill="#799BFF"
      />
      <path
        d="M18.2045 172.795H9.07227V181.927H18.2045V172.795Z"
        fill="#799BFF"
      />
      <path d="M9.0725 18.2051H0V172.796H9.0725V18.2051Z" fill="#799BFF" />
      <mask id="path-12-inside-1_7438_26622" fill="white">
        <rect y="8" width="191" height="186" rx="5" />
      </mask>
      <rect
        y="8"
        width="191"
        height="186"
        rx="5"
        stroke="#799BFF"
        strokeWidth="36"
        mask="url(#path-12-inside-1_7438_26622)"
      />
    </svg>
  );
};
