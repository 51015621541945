import { HeadingProfile } from '../../../../components/Heading/HeadingProfile';
import card from '../../../../assets/images/card.svg';
import { useContext } from 'react';
import { TranslationsContext } from '../../../../context/TranslationsContext';


export const AnotherPayments = () => {
  const { profileTranslations } = useContext(TranslationsContext);
  return (
    <section className="mb-25">
      <HeadingProfile svg={card} className="mb-15">
        {profileTranslations?.profile?.otherPaymentWays}
      </HeadingProfile>
      <div className="block_container">
        <HeadingProfile className="mb-15">{profileTranslations?.profile?.otherPaymentWays} (Google Play):</HeadingProfile>
        <ul className="mb-25">
          {profileTranslations?.profile?.otherPaymentGoogle?.map((payment, index) => (
            <li key={index} className="subtitle">
              {payment}
            </li>
          ))}
        </ul>
        <HeadingProfile className="mb-15">{profileTranslations?.profile?.otherPaymentWays} (App Store):</HeadingProfile>
        <ul>
          {profileTranslations?.profile?.otherPaymentApple?.map((payment, index) => (
            <li key={index} className="subtitle">
              {payment}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
