import Image1 from '../../../../assets/images/main/instruction/instruction-1.png';
import Image2 from '../../../../assets/images/main/instruction/instruction-2.png';
import Image3 from '../../../../assets/images/main/instruction/instruction-3.png';
import Image4 from '../../../../assets/images/main/instruction/instruction-4.png';
import Image5 from '../../../../assets/images/main/instruction/instruction-5.png';
import Image6 from '../../../../assets/images/main/instruction/instruction-6.png';
import Image7 from '../../../../assets/images/main/instruction/instruction-7.png';
import Image8 from '../../../../assets/images/main/instruction/instruction-8.png';
import Image9 from '../../../../assets/images/main/instruction/instruction-9.png';
import Image10 from '../../../../assets/images/main/instruction/instruction-10.png';
import Image11 from '../../../../assets/images/main/instruction/instruction-11.png';
import Image12 from '../../../../assets/images/main/instruction/instruction-12.png';
import Image13 from '../../../../assets/images/main/instruction/instruction-13.png';
import Image14 from '../../../../assets/images/main/instruction/instruction-14.png';
import Image1Small from '../../../../assets/images/main/instruction/instruction-1-small.png';
import Image2Small from '../../../../assets/images/main/instruction/instruction-2-small.png';
import Image3Small from '../../../../assets/images/main/instruction/instruction-3-small.png';
import Image4Small from '../../../../assets/images/main/instruction/instruction-4-small.png';
import Image5Small from '../../../../assets/images/main/instruction/instruction-5-small.png';
import Image6Small from '../../../../assets/images/main/instruction/instruction-6-small.png';
import Image7Small from '../../../../assets/images/main/instruction/instruction-7-small.png';
import Image8Small from '../../../../assets/images/main/instruction/instruction-8-small.png';
import Image9Small from '../../../../assets/images/main/instruction/instruction-9-small.png';
import Image10Small from '../../../../assets/images/main/instruction/instruction-10-small.png';
import Image11Small from '../../../../assets/images/main/instruction/instruction-11-small.png';
import Image12Small from '../../../../assets/images/main/instruction/instruction-12-small.png';
import Image13Small from '../../../../assets/images/main/instruction/instruction-13-small.png';
import Image14Small from '../../../../assets/images/main/instruction/instruction-14-small.png';
import {useEffect, useState} from 'react';

const Images = () => {
    const [imgNum, setImgNum] = useState(0);
    const [transition, setTransition] = useState(false)
    const ImgSet = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14];
    const ImgSetSmall = [Image1Small, Image2Small, Image3Small, Image4Small, Image5Small, Image6Small, Image7Small, Image8Small, Image9Small, Image10Small, Image11Small, Image12Small, Image13Small, Image14Small];
    useEffect(() => {
        const interval = setInterval(() => {
            setTransition(true)
            setImgNum(prev => prev < 13 ? prev + 1 : 0);
        }, 16000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setTimeout(() => setTransition(false), 1500)
    }, [transition])

    return (
        <img className={`${transition ? 'change-image' : ''} instructions-background`}
             src={ImgSet[imgNum]}
             srcSet={`${ImgSetSmall[imgNum]} 400w, ${ImgSet[imgNum]} 700w`}
             sizes="(max-width: 440px) 400px, 700px"
        />
    );
};

export default Images;
