import { HeadingProfile } from '../../../../components/Heading/HeadingProfile';
import info from '../../../../assets/images/info.svg';
import infoBlack from '../../../../assets/images/info-black.svg';
import { useContext } from 'react';
import { TranslationsContext } from '../../../../context/TranslationsContext';

export const Instructions = () => {
  const { profileTranslations } = useContext(TranslationsContext);
  return (
    <section className="mb-25">
      <HeadingProfile svg={info} className="mb-15">
        {profileTranslations?.profile?.instruction}
      </HeadingProfile>
      <div className="block_container block_container_double">
        <div className="instructions-block_black">
          <div className="instructions mb-15">
            {profileTranslations?.profile?.instructionDescriptionFirst}
          </div>
          <div className="instructions">
            {profileTranslations?.profile?.instructionDescriptionSecond}
          </div>
        </div>
        <div className="instructions-block_yellow">
          <img src={infoBlack} alt="" />
          {profileTranslations?.profile?.instructionDescriptionThird}
        </div>
      </div>
    </section>
  );
};
